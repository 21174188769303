import { observable, action, makeObservable } from "mobx";

class Store {
  isSiderClosed = true;
  activeTopNav = window.location.pathname == '/' ? 1 : window.location.pathname == '/features' ? 2 : window.location.pathname == '/blog' ? 3 : window.location.pathname == '/about-us' ? 4 : window.location.pathname == '/contact-us' ? 5 : null  

  constructor() {
    makeObservable(this, {
      isSiderClosed: observable,
      activeTopNav: observable,
      toggleSider: action,
      getTopNavIndex: action,
    });
  }

  toggleSider() {
    this.isSiderClosed = !this.isSiderClosed;
  }

  getTopNavIndex(index) {
    this.activeTopNav = index;
  }
}

export default new Store();
