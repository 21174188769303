import React from "react";
import { Row, Col, Hidden, Visible } from "react-grid-system";
import { observer } from "mobx-react";
import { Button, Input, Checkbox } from "antd";
import { Container } from "components";
import PhoneInput, {parsePhoneNumber} from 'react-phone-number-input';
import lineCurl from "assets/line_curl.svg";
import faqCurl from "assets/faq_curl.svg";
import mapPin from "assets/mapsPin.svg";
import email from "assets/email.svg";
import phone from "assets/phone.svg";
import supportIllustration from "assets/about-us/contact-me lady.svg";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import Store from './store';
import 'react-phone-number-input/style.css'
import faqFlowerPot from 'assets/Faq-flowerPot.svg';

import 'react-telephone-input/css/default.css'
import './styles.css';

const { Panel } = Collapse;
const {TextArea} = Input;

const faq_1 = 'A platform based online learning solution that is readily integrable on existing learning systems. An inclusive e-learning application that has the potential to support physical learning or adequately replace it depending upon requirement. From live classes, assignments and tests, to staff management features and payment gateway, it’s got everything you need to run all academic and managerial processes remotely.';
const faq_2 = 'It’s an all-encompassing software solution for remote learning and its features can be broadly classified into the following categories:';
const faq_3 = 'In aulas, performance remains unchanged (doesn’t degrade) even on internet speeds as low as 70kbps. It is equipped with adaptive bitrate streaming for a smooth experience during intermittent network conditions.';
const faq_4 = 'Any person with a low level of digital literacy can also use aulas comfortably. The product has been designed end-to-end keeping adaptability at the helm of all priorities. Students and teachers who don’t have any prior experience of using smartphones will also be able to adapt to all features with minimum fuss. You needn’t be a techie to teach/learn in aulas. Just show up!';
const faq_5 = 'As an institute, you can use aulas in different ways depending upon your requirement and circumstances. Either you can conduct fully online classes or go for the hybrid option. This can be done by alternatively teaching a fraction of students offline, while the rest can attend their classes from home through the app. Use a projector inside the classroom and teach on the app itself. The objective is to encourage social distancing within the institute premises while maintaining 100% attendance through the app.';
const faq_6 = 'There is no limit to the number of students, teachers and other roles that an institute can add to the platform.';

const Row1 = ({title, subtitle}) => (
  <div className='m-t-50'>
    <div className="title-700 color-black m-b-20">
      {title}
    </div>
    <span className="title-400 color-grey">
      {subtitle}
    </span>
  </div>
)
const PonityRow = ({title, p1, p2, p3, p4, p5='', p6=''}) => (
  <div>
    <div className='color-black font-600'>{title}</div>
    <ul>
      <li className='color-grey'>{p1}</li>
      <li className='color-grey'>{p2}</li>
      <li className='color-grey'>{p3}</li>
      <li className='color-grey'>{p4}</li>
      {p5 === '' ? null : <li className='color-grey'>{p5}</li>}
      {p6 === '' ? null : <li className='color-grey'>{p6}</li>}
    </ul>
  </div>
)

const ReachUs = ({src, text, loc}) => {
  return(
  <>
    <div className="m-t-20" style={{width: '100%'}}>
      <center><img src={src} style={{height: 80, width: 80}}/></center>
      <center style={{width: 280, marginTop: 10, color: '#0779E4', fontFamily: 'Raleway', fontWeight: '700', fontStyle: 'italic'}}>
        {loc == 'mail' ? <a href="mailto:care@aulas.in">care@aulas.in</a> : loc == 'call' ? <a href="tel:
+91 7788998879">
+91 7788998879 </a> : text}
      </center>
    </div>
  </>
  )
}

const ReachUsMob = ({src, text, loc}) => {
  return(
  <>
    <div className="m-t-20 display-flex m-l-20">
      <div><img src={src} style={{ width: '50%', marginTop: -8}}/></div>
      <div style={{width: 230, marginTop: -5, color: '#0779E4', fontFamily: 'Raleway', fontWeight: '700', fontStyle: 'italic'}}>
      {loc == 'mail' ? <a href="mailto:care@aulas.in">care@aulas.in</a> : loc == 'call' ? <a href="tel:+91 7788998879">+91 7788998879 </a> : text}
      </div>
    </div>
  </>
  )
}

const Support = () => (
  <Container>
    <Row style={{ marginTop: 5 }}>
      <Col xs={12} lg={6}>
        <div className="title-800 color-black" style={{fontSize: 30}}>Get in touch with us</div>
        {/* <div style={{ position: "absolute", top: 30, zIndex: -1 }}>
          <img src={lineCurl} alt="" />
        </div> */}
        <div
          className="title-400 color-grey"
          style={{
            marginTop: 20,
            fontSize: 18
          }}
        >
          Please submit your information and our business representative will follow up with you within 24 hours.
        </div>
        <div style={{ marginTop: 30 }}>
          <Input
            placeholder='Full name*'
            className='support-input'
            // style={{borderColor: Store.supportFields.name === '' ? '#F30E5C' : null}}
            onChange={(e) => Store.supportFields.name = e.target.value}
            value={Store.supportFields.name}
          />
          <Input
            placeholder='Organisation name*'
            className='support-input'
            // style={{borderColor: Store.supportFields.org === '' ? '#F30E5C' : null}}
            onChange={(e) => Store.supportFields.org = e.target.value}
            value={Store.supportFields.org}
          />
          <Input
            placeholder='Email*'
            className='support-input'
            // style={{borderColor: Store.supportFields.email === '' ? '#F30E5C' : null}}
            onChange={(e) => Store.supportFields.email = e.target.value}
            value={Store.supportFields.email}
          />
          <PhoneInput
            defaultCountry="IN"
            className='support-input phone-input-wrapper'
            // style={{borderColor: Store.supportFields.phone === '' ? '#F30E5C' : null}}
            value={Store.supportFields.phone}
            onChange={(string) => {
              if(string === undefined){
                Store.supportFields.phone = '';
              }else {
                if(parsePhoneNumber(string)!== undefined ){
                  Store.supportFields.phone = parsePhoneNumber(string).number
                  Store.supportFields.country = parsePhoneNumber(string).country
                }
              }
            }} />
          <TextArea
            placeholder='Let us know what you need*'
            className='support-input'
            // style={{borderColor: Store.supportFields.message === '' ? '#F30E5C' : null}}
            onChange={(e) => Store.supportFields.message = e.target.value}
            value={Store.supportFields.message}
            // size={"large"}
          />
          <div style={{marginTop: 10, height: 20, width: '100%'}}>
            <div style={{fontFamily: 'Raleway'}}>
            <Checkbox style={{marginRight: 10}}/>
              I agree to the <span style={{color: '#0779E4', fontWeight: 700, fontStyle: 'italic' }}>Terms of service{" "}{" "}</span>and<span style={{color: '#0779E4', fontWeight: 700, fontStyle: 'italic' }}>{" "}Privacy policy</span>
            </div>
          </div>
          <Button
            loading={Store.supportFieldsLoader}
            onClick={() => Store.requestEnquiry()}
            size={'large'}
            style={{width:'100%', height:'55px'}}
            className='home-modal-submit-btn m-t-20'
            >
            <span className='font-600'>SUBMIT</span>
          </Button>
        </div>
      </Col>
      <Hidden xs sm md>
        <Col xs={6}>
          <img src={supportIllustration} alt="Support" style={{height: '70%', marginTop: 110}}/>
        </Col>
      </Hidden>
    </Row>

{/* FOR MOBILE VIEW  */}
  <Hidden sm md lg xl xxl xxxl>
    <Row style={{marginTop: 20, justifyContent: 'center'}}>
      <ReachUsMob src={email} text={"care@aulas.com"} loc={'mail'}/>
      <ReachUsMob src={mapPin} text={`1023, 3rd Floor, 
                                        24th Main Road, 11th A Cross Road,
                                        Sector 1, HSR Layout 
                                        Bengaluru 560102`}/>
      <ReachUsMob src={phone} text={"+91 7788998879" } loc={'call'}/>
    </Row>
  </Hidden>

{/* FOR WEB VIEW  */}
  <Visible sm md lg xl xxl xxxl>
    <center className="r-sb-c" style={{marginTop: 144}}>
      <ReachUs src={email} text={"care@aulas.com"} loc={'mail'}/>
      <ReachUs src={mapPin} text={`1023, 3rd Floor, 
                                        24th Main Road, 11th A Cross Road,
                                        Sector 1, HSR Layout 
                                        Bengaluru 560102`}/>
      <ReachUs src={phone} text={"+91 7788998879" } loc={'call'}/>
    </center>
  </Visible>

    
{/* FOR MOBILE VIEW  */}
    <Hidden sm md>
    <center style={{marginTop: 50}}>
      <img src={faqFlowerPot} alt=''/>
    </center>
    </Hidden>

{/* FOR WEB VIEW  */}
    <Visible sm md lg>
    <center style={{marginTop: 144}}>
      <img src={faqFlowerPot} alt=''/>
    </center>
    </Visible>
    <div className='position-relative r-c-c faq-header-wrapper'>
      <div className="title-800 color-black" style={{marginTop: 20, textAlign: 'center', fontSize: 30}}>
        Frequently asked questions
      </div>
    </div>

{/* FOR MOBILE VIEW  */}
    <Hidden sm md>
    <Collapse
        // ghost
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        expandIconPosition="right"
        style={{ marginTop: 72 }}
        accordion
      >
        <Panel key="1" header={<span className="title-400 font-600 color-black m-b-20">What is aulas?</span>}>
          <p className="title-400 color-grey m-b-20">{faq_1}</p>
        </Panel>
        <Panel key="2" header={<span className="title-400 font-600 color-black m-b-20">What features does aulas provide?</span>} >
          <div>
            <div className="title-400 m-b-10 color-grey">{faq_2}</div>
            <div className='color-black font-600 m-b-10'>a. Live Class:
              <span className='color-grey font-400'> Hand Raise | Touch-to-Talk | Chat-Box | Digital Whiteboard | Live Recording | Screen Sharing </span>
            </div>
            <div className='color-black font-600 m-b-10'>b. Planning & Organizing:
              <span className='color-grey font-400'> User Roles | User Privileges | Schedule | Attendance (students & staff) | Payment Gateway (Fee management) | Announcements </span>
            </div>
            <div className='color-black font-600 m-b-10'>c. Assignment & Test Portal:
              <span className='color-grey font-400'> Creation | Publishing | Answering | Submission | Evaluation | Review </span>
            </div>
            <div className='color-black font-600'>d. Digital Library:
              <span className='color-grey font-400'> Video Lectures | Question Banks | Lecture Notes | Recorded Sessions </span>
            </div>
          </div>
        </Panel>
        <Panel key="3" header={<span className="title-400 font-600 color-black m-b-20">How can an institute get onboard aulas?</span>} >
          <div>
            <div className="title-400 m-b-10 color-grey">The onboarding process is short, simple and seamless. Visit
            our website at <a href='https://aulas.in/register' target='_blank()'> aulas.in </a> and request us for a demo. It takes 24 hours to get your
            institute onboard aulas.</div>
          </div>
        </Panel>
        <Panel key="4" header={<span className="title-400 font-600 color-black m-b-20">How stable is aulas in low internet?</span>} >
          <p className="title-400 color-grey m-b-20">{faq_3}</p>
        </Panel>
        <Panel key="5" header={<span className="title-400 font-600 color-black m-b-20">Technical specifications?</span>} >
          <div>
            <div className="title-400 m-b-10 color-grey"><span className='font-600'>OS Requirement: </span> Windows, MacOS, LINUX, Android (version 7 or higher)/iOS (version 11 or higher)</div>
            <div className="title-400 color-grey"><span className='font-600'>Hardware: </span> Desktop/Laptop/Tablet/Smartphone</div>
          </div>
        </Panel>
        <Panel key="6" header={<span className="title-400 font-600 color-black m-b-20">What level of digital literacy is required to use aulas?</span>} >
          <p className="title-400 color-grey m-b-20">{faq_4}</p>
        </Panel>
        <Panel key="7" header={<span className="title-400 font-600 color-black m-b-20">How many users can be added on aulas?</span>} >
          <p className="title-400 color-grey m-b-20">{faq_6}</p>
        </Panel>
        <Panel key="8" header={<span className="title-400 font-600 color-black m-b-20">How can hybrid classes be conducted using aulas?</span>} >
          <p className="title-400 color-grey m-b-20">{faq_5}</p>
        </Panel>
      </Collapse>
    </Hidden>

{/* FOR WEB VIEW  */}
    <Visible sm md lg>
      {/* <Collapse
        // ghost
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        expandIconPosition="right"
        style={{ marginTop: 72 }}
        accordion
      > */}
        <Panel key="1" header={<span className="title-400 font-600 color-black m-b-20">What is aulas?</span>}>
          <p className="title-400 color-grey m-b-20">{faq_1}</p>
        </Panel>
        <Panel key="2" header={<span className="title-400 font-600 color-black m-b-20">What features does aulas provide?</span>} >
          <div>
            <div className="title-400 m-b-10 color-grey">{faq_2}</div>
            <div className='color-black font-600 m-b-10'>a) Live Class:
              <span className='color-grey font-400'> Hand Raise | Touch-to-Talk | Chat-Box | Digital Whiteboard | Live Recording | Screen Sharing </span>
            </div>
            <div className='color-black font-600 m-b-10'>b) Planning & Organizing:
              <span className='color-grey font-400'> User Roles | User Privileges | Schedule | Attendance (students & staff) | Payment Gateway (Fee management) | Announcements </span>
            </div>
            <div className='color-black font-600 m-b-10'>c) Assignment & Test Portal:
              <span className='color-grey font-400'> Creation | Publishing | Answering | Submission | Evaluation | Review </span>
            </div>
            <div className='color-black font-600'>d) Digital Library:
              <span className='color-grey font-400'> Video Lectures | Question Banks | Lecture Notes | Recorded Sessions </span>
            </div>
          </div>
        </Panel>
        <Panel key="3" header={<span className="title-400 font-600 color-black m-b-20">How can an institute get onboard aulas?</span>} >
          <div>
            <div className="title-400 m-b-10 color-grey">The onboarding process is short, simple and seamless. Visit
            our website at <a href='https://aulas.in/register' target='_blank()'> aulas.in </a> and request us for a demo. It takes 24 hours to get your
            institute onboard aulas.</div>
          </div>
        </Panel>
        <Panel key="7" header={<span className="title-400 font-600 color-black m-b-20">How stable is aulas in low internet?</span>} >
          <p className="title-400 color-grey m-b-20">{faq_3}</p>
        </Panel>
        <Panel key="5" header={<span className="title-400 font-600 color-black m-b-20">Technical specifications?</span>} >
          <div>
            <div className="title-400 m-b-10 color-grey"><span className='font-600'>OS Requirement: </span> Windows, MacOS, LINUX, Android (version 7 or higher)/iOS (version 11 or higher)</div>
            <div className="title-400 color-grey"><span className='font-600'>Hardware: </span> Desktop/Laptop/Tablet/Smartphone</div>
          </div>
        </Panel>
        
        <Panel key="6" header={<span className="title-400 font-600 color-black m-b-20">What level of digital literacy is required to use aulas?</span>} >
          <p className="title-400 color-grey m-b-20">{faq_4}</p>
        </Panel>
        <Panel key="7" header={<span className="title-400 font-600 color-black m-b-20">How many users can be added on aulas?</span>} >
          <p className="title-400 color-grey m-b-20">{faq_6}</p>
        </Panel>
        <Panel key="8" header={<span className="title-400 font-600 color-black m-b-20">How can hybrid classes be conducted using aulas?</span>} >
          <p className="title-400 color-grey m-b-20">{faq_5}</p>
        </Panel>
      {/* </Collapse> */}
    </Visible>
  </Container>
);

export default observer(Support);
