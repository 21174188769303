import React from 'react';
import Result from 'antd/lib/result';
import 'antd/lib/result/style/index';

class Success extends React.Component {

  render() {
    console.log('requestFields',window.location.href);
    const url = new URL(window.location.href);
    const email = url.searchParams.get("email");
    return (
      <div style={{height: '100vh'}} className="r-c-c">
        <Result
          status="success"
          title="Request sent successfully!"
          subTitle="You may close this window now"
          extra={[
            <p className="ant-result-subtitle">{`Please find aulas Onboarding Form sent to your email ID (${email})`}</p>
          ]}
        />
      </div>

    )
  }
}


export default Success;
