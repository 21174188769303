import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Button } from 'antd';
import { Visible, Hidden } from "react-grid-system";
import { CloseOutlined, AlignRightOutlined } from "@ant-design/icons";
import Store from "services/store";
import HomeStore from "pages/home/store";
import RequestModal from "pages/home/requestModal";

const classroomLogo = require("assets/newLogo.svg");

const handleMenuPress = () => {
  Store.toggleSider();
};


class CustomHeader extends React.Component {


  pageGetTopNavIndex = (index) => {
    Store.getTopNavIndex(index);
    HomeStore.clearRequestDemo();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  render() {
    const { history } = this.props;
    return (
      <div /* style={{ zIndex: 103 }} */>
        <Hidden xs sm>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              onClick={() => { this.pageGetTopNavIndex(1); window.location = "/" }}
              src={classroomLogo.default}
              alt="The Classroom App"
              style={{ width: 150 }}
              className='cursor-pointer'
            />
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <div className='flex-1 r-c-c'>
                <span style={{ display: "flex", maxWidth: 600, width: '100%', justifyContent: "space-around" }}>
                  <Link
                    className="subtitle-500 color-black"
                    to="/"
                    onClick={() => this.pageGetTopNavIndex(1)}
                    style={{ color: Store.activeTopNav === 1 ? '#0779E4' : '#393e46', fontWeight: Store.activeTopNav === 1 ? '600' : '400' }}
                  >
                    HOME
                  </Link>
                  
                  <Link
                    to="/features"
                    className="subtitle-500 color-black"
                    onClick={() => this.pageGetTopNavIndex(2)}
                    style={{ color: Store.activeTopNav === 2 ? '#0779E4' : '#393e46', fontWeight: Store.activeTopNav === 2 ? '600' : '400' }}
                  >
                    FEATURES
                  </Link>


                  {/* <Link

                    to="/features"
                    className="subtitle-500 color-black"
                    onClick={() => this.pageGetTopNavIndex(3)}
                    style={{ color: Store.activeTopNav === 3 ? '#0779E4' : '#393e46', fontWeight: Store.activeTopNav === 3 ? '600' : '400' }}
                  >
                    PLANS
                  </Link> */}


                  <Link
                    to="/blog"
                    className="subtitle-500 color-black"
                    onClick={() => this.pageGetTopNavIndex(3)}
                    style={{ color: Store.activeTopNav === 3 ? '#0779E4' : '#393e46', fontWeight: Store.activeTopNav === 3 ? '600' : '400' }}
                  >
                    BLOG
                  </Link>

                  <Link
                    to="/about-us"
                    className="subtitle-500 color-black"
                    onClick={() => this.pageGetTopNavIndex(4)}
                    style={{color: Store.activeTopNav === 4 ? '#0779E4' : '#393e46', fontWeight: Store.activeTopNav === 4 ? '600' : '400'}}
                  >
                    ABOUT US
                  </Link>
                  
                  <Link
                    to="/contact-us"
                    className="subtitle-500 color-black"
                    onClick={() => this.pageGetTopNavIndex(5)}
                    style={{color: Store.activeTopNav === 5 ? '#0779E4' : '#393e46', fontWeight: Store.activeTopNav === 5 ? '600' : '400'}}
                  >
                    CONTACT US
                  </Link>
                </span>
              </div>
              <div>
                <Button onClick={() => HomeStore.showRequestModal(true)} size={'large'} style={{ backgroundColor: "#594099", padding: '0px 15px', }}>
                  <span className='font-600' style={{ color: "#FFFFFF" }}>REQUEST DEMO</span>
                </Button>
              </div>
            </div>
          </div>
        </Hidden>
        <Visible xs sm>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <img
              onClick={() => { this.pageGetTopNavIndex(1); window.location = "/" }}
              src={classroomLogo.default}
              alt="The Classroom App"
              style={{ width: 150 }}
              className='cursor-pointer'
            />
            <div className="color-black title-700" onClick={handleMenuPress}>
              {false ? <CloseOutlined /> : <AlignRightOutlined />}
            </div>
          </div>
        </Visible>
        <RequestModal />
      </div>
    )
  }
}

export default observer(CustomHeader);
