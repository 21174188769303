import React from "react";
import image from "assets/404.svg";
import { Button } from "antd";

class FourOFourPage extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <img
          src={image}
          alt="404"
          style={{ height: 500, maxWidth: "100%" }}
        />
        <div className="title-400 color-black m-b-20 m-t-20 font-600">
          Looks like the page you are looking for does not exist
        </div>
        <Button
          onClick={() => {
            history.push("/");
          }}
          className="font-600 home-modal-submit-btn ant-btn-lg"
        >
          <span className="font-600">TAKE ME 🏠</span>
        </Button>
      </div>
    );
  }
}

export default FourOFourPage;
