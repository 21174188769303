import React, { useEffect } from 'react';
import { Button, Row, Col } from "antd";
import planning from "assets/features/planning.svg";


const Redirect = () => {


   
    return (
      <Row justify='center'>
      <Row justify='center'>
        <Col>
          <div className='title-500 color-black m-b-10 font-600' style={{textAlign: 'center'}}>
            Redirecting...
          </div>
          <img src={planning} alt=""  style={{maxWidth:'90%'}}/>
        </Col>
        </Row>
        <Row justify='center' className='m-t-20'>
          <Col style={{textAlign: 'center'}}>
            <div className="title-400 color-grey">If you are not redirected to the aulas app automatically, please click the button below</div>
            <Button
                  onClick={() => {
                    window.location.href=`aulas://app${window.location.search}`
                  }}
                  className="font-600 home-modal-submit-btn ant-btn-lg m-t-20"
                >
              <span className="font-600">REDIRECT</span>
            </Button>
          </Col>
      </Row>
      </Row>
       
    )
};

export default Redirect;