import { Carousel } from 'antd';
import React from 'react';
import Rectangle_3699 from "assets/home/Rectangle_3699.svg"
import Vector1_2 from "assets/home/Vector1_2.svg"
import Vector1_1 from "assets/home/Vector1_1.svg"
import Ellipse_8 from "assets/home/Ellipse_8.svg"
import Rankers_Point from "assets/home/Rankers_Point.svg"
import techlang12 from "assets/home/techlang12.jpg"
import teachlang from "assets/home/teachlang.svg"
import scc from "assets/home/scc.svg"
import sanjay from "assets/home/sanjay.png"
import amith from "assets/home/amith.jpg"
import briliant from "assets/home/briliant.svg"
import Abhishek from "assets/home/Abhishek.jpg"
import { Hidden, Visible } from 'react-grid-system';
import book1 from '../assets/home/book1.svg'

const contentStyle = {
    height: '160px',
    BackgroundColor: 'red',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

let alokDixittext = `I have been acquainted with quite a few online learning applications
that have come up in the recent past and the reason i have a soft
corner for aulas is that they have a very fundamental approach to
problem solving. It wouldn't in fact be wrong to address them as a
"People's app" that does not follow a one-size-fits-all approach and
treats every customer with attention and care. Every time i have
approached aulas with digital on-boarding concerns, they have
either solved my problem completely or provided an adequate work-
around. Treating clients in this manner in the service industry is
quite common but aulas is a product and for me personally, they
have set a new benchmark for the quality of client support in this
regard. Much gratitude for everything.`

let vectorsAcademyText = `When the pandemic started in early 2020, we were really at a loss
for ideas on how to sustain operations amid a complete breakdown
of physical infrastructure. aulas was nothing less than a saviour
which provided us with the means to keep our classes going through
its in-house live class mode (online) which was much better suited
to our needs than any other popular application at the time which
were more adept for business conferences and not really for a live
classroom. As time has gone by, physical classrooms are back in
business and this where i commend the manner in which aulas has
grown from initially just a lockdown solution to a comprehensive 360
degree solution which can now also support a the hybrid model of
functioning adequately. aulas is user friendly, inexpensive and high
quality. It has allowed us not only to stay afloat but also make
steady strides of growth.`

let sccText = `We have been associated with *aulas* and it has been a great
experience working with them. Their entire team has been very
supportive in understanding our needs and giving their best to
incorporate it into the system, especially the aulas support team
team, who has been extremely patient, responsive, devout and a
pure pleasure to work with. *aulas* is a very simple and user-
friendly platform, and we’re looking forward to a long-term
association with them. Thank you *aulas* for helping me & my team
to build the online digital brand.`

let techlangText = `A Big 'Thank You' to the aulas team for the amazing support and
trunkful of patience. We couldn't have started this journey without
the aulas support team team having taken the time and effort to
understand our business model and limitations and proposing the
solution to address our needs within the defined limitations. The
Training team has been patient, supportive, responsive to our
requests and queries (even on weekends). The positive 'yes, we can
do' attitude has got us going on our first milestone. We look forward
to a long-term profitable collaboration with the team`

let brilliantEdupediaText = `I have been using Aulas app since last 1 year and my experience is
just awesome. It is a very user friendly app and fullfills all the
requirements of a coaching Institute. The aulas support team is also
very helping and we get the timely assistance when required.
Overall very good experience.`

const CarouselData = () => (
    <div>
        <Visible sm md lg xl xxl >
            <div className='title-large-800 color-black' style={{ fontSize: "1.9rem", textAlign: "center", marginTop: "50px" }}>What our users say about us</div>
        </Visible>
        <Hidden md lg xl xxl>
            <div className='title-large-800 color-black' style={{ fontSize: "1.2rem", textAlign: "center", marginTop: "50px" }}>What our users say about us</div>
        </Hidden>
        <Visible sm md lg xl xxl xxxl>
        <Carousel autoplay={true}>
            <div>
                <div style={{ contentStyle, marginTop: "35px", display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                    <img src={Rectangle_3699} alt="" />
                </div>
                <div className='subtitle-500 color-grey' style={{ textAlign: "justify", fontSize: "1.1rem", textAlign: "center" }}>
                   {alokDixittext}
                </div>
                <div className='title-large-800 color-black' style={{ fontSize: "1.9rem", textAlign: "center", marginTop: "10px" }}>Alok Dixit</div>
                <div className='subtitle-500 color-grey' style={{ fontSize: "1.1rem", textAlign: "center", marginTop: "10px" }}>General Secretary, Coaching Federation of India</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", marginTop: "18px" }}>
                    <img src={Ellipse_8} alt="" />
                    <img style={{ marginLeft: "15px" }} src={Rankers_Point} alt="" />
                </div>
            </div>

            <div>
                <div style={{ contentStyle, marginTop: "35px", display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                    <img src={techlang12} alt="" style={{ marginBottom: "55px", width: "15%" }} />
                </div>
                <div className='subtitle-500 color-grey' style={{ textAlign: "justify", fontSize: "1.1rem", textAlign: "center" }}>
                    {techlangText}
                </div>
                <div className='title-large-800 color-black' style={{ fontSize: "1.9rem", textAlign: "center", marginTop: "10px" }}>Anshu Kumari</div>
                <div className='subtitle-500 color-grey' style={{ fontSize: "1.1rem", textAlign: "center", marginTop: "10px" }}>Techlang</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", marginTop: "18px" }}>
                    <img src={teachlang} alt="" />
                </div>
            </div>

            <div>
                <div style={{ contentStyle, marginTop: "35px", display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                    <img src={sanjay} alt="" style={{ marginBottom: "55px", width: "15%" }} />
                </div>
                <div className='subtitle-500 color-grey' style={{ textAlign: "justify", fontSize: "1.1rem", textAlign: "center" }}>
                   {sccText}
                </div>
                <div className='title-large-800 color-black' style={{ fontSize: "1.9rem", textAlign: "center", marginTop: "10px" }}>Sanjay Jayram Atkari</div>
                <div className='subtitle-500 color-grey' style={{ fontSize: "1.1rem", textAlign: "center", marginTop: "10px" }}>Sharada coaching classess</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", marginTop: "18px" }}>
                    <img src={scc} alt="" />
                </div>
            </div>

            <div>
                <div style={{ contentStyle, marginTop: "35px", display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                    <img src={amith} alt="" style={{ marginBottom: "55px", width: "15%" }} />
                </div>
                <div className='subtitle-500 color-grey' style={{ textAlign: "justify", fontSize: "1.1rem", textAlign: "center" }}>
                    {vectorsAcademyText}
                </div>
                <div className='title-large-800 color-black' style={{ fontSize: "1.9rem", textAlign: "center", marginTop: "5px" }}>Amit Singh (Academic Head)</div>
                <div className='subtitle-500 color-grey' style={{ fontSize: "1.1rem", textAlign: "center", marginTop: "10px" }}>Vectors Academy</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", marginTop: "18px" }}>
                    <img src={book1} alt="" />
                    {/* <div className='subtitle-500 color-grey' style={{ marginLeft: "15px" }}>Brilliant Edupedia</div> */}
                </div>
            </div>

            <div>
                <div style={{ contentStyle, marginTop: "35px", display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                    <img src={Abhishek} alt="" style={{ marginBottom: "55px", width: "15%", height: "15%" }} />
                </div>
                <div className='subtitle-500 color-grey' style={{ textAlign: "justify", fontSize: "1.1rem", textAlign: "center" }}>
                  {brilliantEdupediaText}
                </div>
                <div className='title-large-800 color-black' style={{ fontSize: "1.9rem", textAlign: "center", marginTop: "10px" }}>Abhishek Kulkarni</div>
                <div className='subtitle-500 color-grey' style={{ fontSize: "1.1rem", textAlign: "center", marginTop: "10px" }}>Brilliant Edupedia</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", marginTop: "18px" }}>
                    <img src={briliant} alt="" />
                </div>
            </div>
        </Carousel>
        </Visible>

        <Hidden sm md lg xl xxl xxxl>
        <Carousel autoplay={true}>
            <div>
                <div style={{ contentStyle, marginTop: "35px", display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                    <img src={Rectangle_3699} alt="" />
                </div>
                <div className='subtitle-500 color-grey' style={{ textAlign: "justify", fontSize: "0.8rem", textAlign: "center" }}>
                   {alokDixittext}
                </div>
                <div className='title-large-800 color-black' style={{ fontSize: "1.4rem", textAlign: "center", marginTop: "10px" }}>Alok Dixit</div>
                <div className='subtitle-500 color-grey' style={{ fontSize: "1.1rem", textAlign: "center", marginTop: "10px" }}>General Secretary, Coaching Federation of India</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", marginTop: "18px" }}>
                    <img src={Ellipse_8} alt="" />
                    <img style={{ marginLeft: "15px" }} src={Rankers_Point} alt="" />
                </div>
            </div>

            <div>
                <div style={{ contentStyle, marginTop: "35px", display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                    <img src={techlang12} alt="" style={{ marginBottom: "55px", width: "15%" }} />
                </div>
                <div className='subtitle-500 color-grey' style={{ textAlign: "justify", fontSize: "0.8rem", textAlign: "center" }}>
                    {techlangText}
                </div>
                <div className='title-large-800 color-black' style={{ fontSize: "1.4rem", textAlign: "center", marginTop: "10px" }}>Anshu Kumari</div>
                <div className='subtitle-500 color-grey' style={{ fontSize: "1.1rem", textAlign: "center", marginTop: "10px" }}>Techlang</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", marginTop: "18px" }}>
                    <img src={teachlang} alt="" />
                </div>
            </div>

            <div>
                <div style={{ contentStyle, marginTop: "35px", display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                    <img src={sanjay} alt="" style={{ marginBottom: "55px", width: "15%" }} />
                </div>
                <div className='subtitle-500 color-grey' style={{ textAlign: "justify", fontSize: "0.8rem", textAlign: "center" }}>
                   {sccText}
                </div>
                <div className='title-large-800 color-black' style={{ fontSize: "1.4rem", textAlign: "center", marginTop: "10px" }}>Sanjay Jayram Atkari</div>
                <div className='subtitle-500 color-grey' style={{ fontSize: "1.1rem", textAlign: "center", marginTop: "10px" }}>Sharada coaching classess</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", marginTop: "18px" }}>
                    <img src={scc} alt="" />
                </div>
            </div>

            <div>
                <div style={{ contentStyle, marginTop: "35px", display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                    <img src={amith} alt="" style={{ marginBottom: "55px", width: "15%" }} />
                </div>
                <div className='subtitle-500 color-grey' style={{ textAlign: "justify", fontSize: "0.8rem", textAlign: "center" }}>
                    {vectorsAcademyText}
                </div>
                <div className='title-large-800 color-black' style={{ fontSize: "1.4rem", textAlign: "center", marginTop: "5px" }}>Amit Singh (Academic Head)</div>
                <div className='subtitle-500 color-grey' style={{ fontSize: "1.1rem", textAlign: "center", marginTop: "10px" }}>Vectors Academy</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", marginTop: "18px" }}>
                    <img src={book1} alt="" />
                    {/* <div className='subtitle-500 color-grey' style={{ marginLeft: "15px" }}>Brilliant Edupedia</div> */}
                </div>
            </div>

            <div>
                <div style={{ contentStyle, marginTop: "35px", display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                    <img src={Abhishek} alt="" style={{ marginBottom: "55px", width: "15%", height: "15%" }} />
                </div>
                <div className='subtitle-500 color-grey' style={{ textAlign: "justify", fontSize: "0.8rem", textAlign: "center" }}>
                  {brilliantEdupediaText}
                </div>
                <div className='title-large-800 color-black' style={{ fontSize: "1.4rem", textAlign: "center", marginTop: "10px" }}>Abhishek Kulkarni</div>
                <div className='subtitle-500 color-grey' style={{ fontSize: "1.1rem", textAlign: "center", marginTop: "10px" }}>Brilliant Edupedia</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", marginTop: "18px" }}>
                    <img src={briliant} alt="" />
                </div>
            </div>
        </Carousel>
        </Hidden>
        
    </div>
);

export default CarouselData;