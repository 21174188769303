import React from 'react';
import { Container } from "components";
import aboutUsCurl from "assets/about-us/aboutUsCurl.svg";

const PrivacyPolicy = () => {
    return (
        <div>
            <Container>
                <div className='position-relative m-t-50 m-b-50'>
                    <div className="title-800 color-black">Privacy Policy</div>
                    <div className='position-absolute about-title-wrap'>
                    <img src={aboutUsCurl} alt="" style={{width:'85%'}} />
                    </div>
                </div>

                <div className='text-xs m-t-50 color-black'>This Privacy Policy shall, at all times be read and construed in consonance and along with the User Terms and Conditions (“the UT&C”).
                </div>
                <div className='text-xs m-t-10 color-black'>During the course of the User’s usage of the Platform, VEL may collect the following personal and non-personal information. The information may be collected from the Users as part of the voluntary registration process as well as during any on-line survey or interaction on the Platform, as may be required from time to time. The Platform shall / may collect the following Information from the Users:
                </div>

                <div className='text-sm m-t-30 color-black font-600'>I. Personal Information</div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>i.</div>
                  <div className='text-xs color-black flex-1'>Name:</div>
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>ii.</div>
                  <div className='text-xs color-black flex-1'>Phone number (mobile and / or residence and / or alternative);</div>
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>iii.</div>
                  <div className='text-xs color-black flex-1'>Gender;</div>
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>iv.</div>
                  <div className='text-xs color-black flex-1'>Date of birth;</div>
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>v.</div>
                  <div className='text-xs color-black flex-1'>Address (official / residence / other);</div>
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>vi.</div>
                  <div className='text-xs color-black flex-1'>Email Id (primary / alternative);</div>
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>vii.</div>
                  <div className='text-xs color-black flex-1'>Identifiers like PAN / AADHAR / Voter ID / Institution ID;</div>
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>viii.</div>
                  <div className='text-xs color-black flex-1'>Educational background;</div>
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>ix.</div>
                  <div className='text-xs color-black flex-1'>Photograph;</div>
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>x.</div>
                  <div className='text-xs color-black flex-1'>Internet protocol (IP) address; and</div>
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>xi.</div>
                  <div className='text-xs color-black flex-1'>Sensitive personal data such as passwords.</div>
                </div>

                <div className='text-sm m-t-30 color-black font-600'>II. Non-personal information</div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>i.</div>
                  <div className='text-xs color-black flex-1'>Details of internet or telecom service provider;</div>
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>ii.</div>
                  <div className='text-xs color-black flex-1'>Location;</div>
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>iii.</div>
                  <div className='text-xs color-black flex-1'>Type of internet browser being used; and</div>
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>iv.</div>
                  <div className='text-xs color-black flex-1'>Such other information that may be required to access and operate the Platform.</div>
                </div>

                <div className='text-xs m-t-30 color-black'>In addition to the above, the duration of use of the Platform by the User may also be logged and stored by the Platform.
                </div>
                <div className='text-xs m-t-10 color-black'>Except for the password needed by the User to access the
                Platform, VEL does not collect any “<span className='font-600'>Sensitive Personal Data or Information </span>”, as
                defined under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal
                Data or Information) Rules, 2011 (hereinafter referred to as “<span className='font-600'>IT Rules, 2011</span>”).
                </div>

                <div className='text-xs m-t-30 color-black'>VEL follows a strict policy that:
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>i.</div>
                  <div className='text-xs color-black flex-1'>It shall not collect the User’s personal data unless such personal data is collected for a lawful purpose for which such collection of data is necessary;</div>
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>ii.</div>
                  <div className='text-xs color-black flex-1'>No information except the type mentioned in the Privacy Policy or the UT&C shall be collected;</div>
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>iii.</div>
                  <div className='text-xs color-black flex-1'>It shall not retain any personal data longer than such personal data is required or can be lawfully used;</div>
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>iv.</div>
                  <div className='text-xs color-black flex-1'>It has in place the security practices and procedures prescribed under the IT Rules, 2011;</div>
                </div>
                <div className='display-flex m-t-10'>
                  <div className='text-xs color-black' style={{width:40}}>v.</div>
                  <div className='text-xs color-black flex-1'>It complies with the IT rules, 2011 in respect of the collected Sensitive Personal Data or Information, especially Rule 6, Rule 7 and Rule 8.</div>
                </div>        
            </Container>
      </div>

    )
}

export default PrivacyPolicy;
