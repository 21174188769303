import React from "react";

const json = `{
    "applinks": {
      "apps": [],
      "details": [
        {
          "appID": "5KT2X46ZPU.com.vectors.OauthDemo",
          "paths": [
            "*"
          ]
        }
      ]
    }
  }`;

const AppleUniversalLink = () => {
    return (
        <pre>
           {json} 
        </pre>
    );
};

export default AppleUniversalLink;