import { observable, action, makeObservable, computed } from "mobx";
// import Api from "../../services/Api";
// import AppState from "../../Store";

class Store {

  constructor() {
      makeObservable(this, {
      })
  }

}

export default new Store();
