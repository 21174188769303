import React from "react";
import "design/typography.css";
import "design/color.css";
import "design/utils.css";
import "design/antCssModify.css";
import "antd/dist/antd.css";
import "react-multi-carousel/lib/styles.css";
import Store from "services/store";
import { observer } from "mobx-react";
// import 'react-telephone-input/css/default.css'

import Root from "./root";

class App extends React.Component{
  componentDidMount() {
    document.title = "aulas";
  }

  render(){
    return <div style={{overflow : !Store.isSiderClosed ? 'hidden' : 'unset', height: !Store.isSiderClosed ? '100%' : 'auto' }}>
            <Root />
           </div>;
  }
}

export default observer(App);
