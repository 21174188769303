import React, { useState } from 'react'
import { Row, Col, Hidden, Visible } from "react-grid-system";
import { observer } from "mobx-react";
import { Button, Input, Checkbox } from "antd";
import { Container } from "components";
import benches from '../../assets/blog/benches.svg'
import chain from '../../assets/blog/chain.svg'
import email from '../../assets/blog/email.svg'
import whatsapp from '../../assets/blog/whatsApp.svg'
import graph from '../../assets/blog/blog 1.svg'
import linkedIn from '../../assets/linkedin.svg'
import instagram from '../../assets/instagram.svg'
import twitter from '../../assets/twitter.svg'
import facebook from '../../assets/facebook.svg'
import img1 from '../../assets/blog/img 1.svg'
import img2 from '../../assets/blog/img 2.jpg'
import img3 from '../../assets/blog/img 3.svg'
import img4 from '../../assets/blog/img 4.svg'
import img5 from '../../assets/blog/img 5.svg'
import img6 from '../../assets/blog/img 6.svg'
import img7 from '../../assets/blog/img 7.svg'
import img8 from '../../assets/blog/img 8.svg'
import img9 from '../../assets/blog/img9.jpg'
import back from '../../assets/blog/back-btn.svg'
import { useHistory } from 'react-router-dom';

const BlogHome = ({ setView }) => {

    const history = useHistory();

    const BlogCards = ({ src, title, description, value }) => {
        return (
            <>
                <div className='r-c'>
                    <img src={src} alt='' style={{ width: 387, height: 271, boxShadow: '2px 3px 11px -1px rgba(0,0,0,0.52)', objectFit: 'cover' }} />
                    <div className='title-700 m-l-5' style={{ width: 360, marginTop: 12 }}>{title}</div>
                    <div className='subtitle-700 m-l-5 m-b-50 m-t-10' style={{ width: 320, fontWeight: 400 }}>{description}<span
                        onClick={value}
                        className='cursor-pointer' style={{ fontStyle: 'italic', color: '#0779E4', fontWeight: 700 }}>{"Read full article"}</span></div>
                </div>
            </>
        )
    }


    return (
        <>
            <Visible sm md lg xl xxl xxxl>
                <div className='title-800' style={{ marginBottom: 24, position: 'absolute', marginTop: -80 }}>All articles</div>
                <div className='display-flex flex-wrap m-t-100' style={{ width: '100%', justifyContent: 'space-between', zIndex: 100, position: 'relative' }}>
                        <BlogCards value={() => { history.push({
                            pathname: '/blog/001',
                            state:{
                                key: 1
                            }
                        }); window.scrollTo(0,0) }} src={img1} title={"Covid pandemic shows online learning is here for good: the mental barriers must go"} description={"The Covid-19 pandemic has made one thing abundantly clear – technology is the only answer to all our current mi..."} />
                        <BlogCards value={() => { history.push({
                            pathname: '/blog/002',
                            state:{
                                key: 2
                            }
                        }); window.scrollTo(0,0) }} src={img2} title={"What it takes to be an educational influencer"} description={"An influencer is a loose term in today's time that can be anything for anyone, but at the end of the day, it is supposed to..."} />
                        <BlogCards value={() => { history.push({
                            pathname: '/blog/003',
                            state:{
                                key: 3
                            }
                        }); window.scrollTo(0,0) }} src={img3} title={"10 reasons why e-learning is more impactful on younger students"} description={"Online education, even in the current era, faces a certain level of backlash. For many, it is still a debatable subject whe..."} />
                        <BlogCards value={() => { history.push({
                            pathname: '/blog/004',
                            state:{
                                key: 4
                            }
                        }); window.scrollTo(0,0) }} src={img4} title={"A luxury is now a necessity"} description={"The year 2020 and the Covid- 19 pandemic have been nothing less than a nemesis for all mankind, but they have ma..."} />
                        <BlogCards value={() => { history.push({
                            pathname: '/blog/005',
                            state:{
                                key: 5
                            }
                        }); window.scrollTo(0,0) }} src={img5} title={"How Adapting E-education became essential for the teaching industry."} description={" The past teaches us the lessons that strengthen our future. COVID-19 turned out to be an unexpected lesson... "} />
                        <BlogCards value={() => { history.push({
                            pathname: '/blog/006',
                            state:{
                                key: 6
                            }
                        }); window.scrollTo(0,0) }} src={img6} title={"How is online education breaking barriers?"} description={"Could anyone ever imagine online education having the ability to not only change the face of education but also be a p... "} />
                        <BlogCards value={() => { history.push({
                            pathname: '/blog/007',
                            state:{
                                key: 7
                            }
                        }); window.scrollTo(0,0) }} src={img7} title={"The huge merits of online teaching"} description={"The year 2020 and the COVID-19 pandemic have been nothing less than a nemesis for all of mankind, but they have m..."}/>
                        <BlogCards value={() => { history.push({
                            pathname: '/blog/008',
                            state:{
                                key: 8
                            }
                        }); window.scrollTo(0,0) }} src={img8} title={"What hasn’t changed and never will"} description={"The ideas of change and innovation in world, might seem like the only constants are in reality, (most..."}/>
                        <BlogCards value={() => { history.push({
                            pathname: '/blog-detail',
                            state:{
                                key: 9
                            }
                        }); window.scrollTo(0,0) }} src={img9} title={"How online education can reduce the effects of learning disabilities"} description={"The year 2020 and the COVID-19 pandemic have been nothing less than a nemesis for all mankind, but they have made one..."}/>
                </div>
            </Visible>

            <Hidden sm md lg xl xxl xxxl>
                <div style={{ marginLeft: -3 }}>
                    <div className='title-800' style={{ marginBottom: 4, position: 'absolute', marginTop: - 70 }}>All articles</div>
                    <div className='display-flex flex-wrap m-t-50' style={{ width: '100%', justifyContent: 'space-between', position: 'relative' }}>
                    <BlogCards value={() => { history.push({
                            pathname: '/blog-detail',
                            state:{
                                key: 1
                            }
                        }); window.scrollTo(0,0) }} src={img1} title={"Covid pandemic shows online learning is here for good: the mental barriers must go"} description={"The Covid-19 pandemic has made one thing abundantly clear – technology is the only answer to all our current mi..."} />
                        <BlogCards value={() => { history.push({
                            pathname: '/blog-detail',
                            state:{
                                key: 2
                            }
                        }); window.scrollTo(0,0) }} src={img2} title={"What it takes to be an educational influencer"} description={"An influencer is a loose term in today's time that can be anything for anyone, but at the end of the day, it is supposed to..."} />
                        <BlogCards value={() => { history.push({
                            pathname: '/blog-detail',
                            state:{
                                key: 3
                            }
                        }); window.scrollTo(0,0) }} src={img3} title={"10 reasons why e-learning is more impactful on younger students"} description={"Online education, even in the current era, faces a certain level of backlash. For many, it is still a debatable subject whe..."} />
                        <BlogCards value={() => { history.push({
                            pathname: '/blog-detail',
                            state:{
                                key: 4
                            }
                        }); window.scrollTo(0,0) }} src={img4} title={"A luxury is now a necessity"} description={"The year 2020 and the Covid- 19 pandemic have been nothing less than a nemesis for all mankind, but they have ma..."} />
                        <BlogCards value={() => { history.push({
                            pathname: '/blog-detail',
                            state:{
                                key: 5
                            }
                        }); window.scrollTo(0,0) }} src={img5} title={"How Adapting E-education became essential for the teaching industry."} description={" The past teaches us the lessons that strengthen our future. COVID-19 turned out to be an unexpected lesson... "} />
                        <BlogCards value={() => { history.push({
                            pathname: '/blog-detail',
                            state:{
                                key: 6
                            }
                        }); window.scrollTo(0,0) }} src={img6} title={"How is online education breaking barriers?"} description={"Could anyone ever imagine online education having the ability to not only change the face of education but also be a p... "} />
                        <BlogCards value={() => { history.push({
                            pathname: '/blog-detail',
                            state:{
                                key: 7
                            }
                        }); window.scrollTo(0,0) }} src={img7} title={"The huge merits of online teaching"} description={"The year 2020 and the COVID-19 pandemic have been nothing less than a nemesis for all of mankind, but they have m..."}/>
                        <BlogCards value={() => { history.push({
                            pathname: '/blog-detail',
                            state:{
                                key: 8
                            }
                        }); window.scrollTo(0,0) }} src={img8} title={"What hasn’t changed and never will"} description={"The ideas of change and innovation in world, might seem like the only constants are in reality, (most..."}/>
                        <BlogCards value={() => { history.push({
                            pathname: '/blog-detail',
                            state:{
                                key: 9
                            }
                        }); window.scrollTo(0,0) }} src={img9} title={"How online education can reduce the effects of learning disabilities"} description={"The year 2020 and the COVID-19 pandemic have been nothing less than a nemesis for all mankind, but they have made one..."}/>

                       </div>
                </div>
            </Hidden>
        </>
    )
}

const Blog1 = ({setView}) => {

    let p1 = `The Covid-19 pandemic has made one thing abundantly clear – technology is the only answer to all our current miseries, constrictions and incompetence. And while every person and business has suffered, the future of education worries me the most.`

    let p2 = `The alternatives to in-person learning provided by information and communication technologies are not completely ready, nor are the teachers and students who are supposed to use them, especially in less developed societies. In fact, technology, as presently utilised in schools and colleges, is more a problem than a solution for countries like India.`

    let p3 = `Not every teacher or student is adept at using computers and smartphones. Hundreds of thousands of students do not even possess smartphones. For those who do, connectivity can be a challenge.
    `

    let p4 = `While the list of technical, financial, logistical and other reasons why teachers (barring a small fraction of those belonging to high-end schools in urban areas) are struggling to deliver online learning is endless, the mental acceptance of this mode of learning seems to be the bigger hurdle (“In coronavirus new normal, give teachers time to learn”, December 25).`

    let p5 = `Does the average human mind react similarly to all kinds of change, especially when it’s sudden? Not too long ago, when concepts like e-commerce and online retailing were introduced, they were greeted by consumer scepticism and fears about data security and credit card theft. Today, e-commerce giants rule the roost worldwide.`

    let p6 = `Going further back in time, the telephone was at first a thing of awe and fear rather than admiration. Guglielmo Marconi, inventor of the radio, doubted his own invention and once said, “Have I done the world good, or have I added a menace?”`

    let p7 = `Many sensible changes, which eventually gained widespread acceptance, were not initially welcomed by those who lived through the transition.`

    let p8 = `In the post-pandemic world, much will change as online teaching emerges as an option worldwide. An infrastructure that can cater to the needs of students and teachers in the remotest corners of the country will not only address technical gaps but will also produce acceptability. Confidence that online services will be available to all has to be built through careful preparation.`

    let p9 = `The mental transformation required to move from a familiar and time-tested teaching methodology to a whole new virtual world of teaching will not be easy, but the luxury of leveraging technology in education has now become a necessity.`

    
    return (
        <div>
            <Visible sm md lg xl xxl>
                <div style={{ position: 'relative' }}>
                    <img src={img1} alt='' style={{ width: 1347, height: '300px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', top: 20, cursor: 'pointer' }} src={back} />
                </div>
                <Row style={{ width: '103%' }}>
                    <div className='r-c'>
                        <div className='subtitle-700 font-700' style={{ marginLeft: 159, marginTop: 59, marginBottom: 32 }}>Share</div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '5%', justifyContent: 'space-between', marginLeft: '80.5%' }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </div>
                    </div>

                    <Col>
                    <div className='title-700 m-l-20 m-t-40' style={{ width: '100%', }}>Covid pandemic shows online learning is here for good: the mental barriers must go</div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p1}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Knowledge
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p2}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            The presence of mind
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p3}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Charisma
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p4}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Camera Presence
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p5}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Understanding the online community
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p6}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Influence
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-10'>
                            <div className='subtitle-500'>{p7}</div>
                        </div>

                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-10'>
                            <div className='subtitle-500'>{p8}</div>
                        </div>

                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-10'>
                            <div className='subtitle-500'>{p9}</div>
                        </div>

                      
                    </Col>
                </Row>
            </Visible>

            <Hidden sm md lg xl xxl xxxl>
                <div style={{ position: 'relative' }}>
                    <img src={img2} alt='' style={{ width: 470, height: '200px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', left: 0, top: 10, cursor: 'pointer' }} src={back} />
                </div>
                <Col style={{ width: '103%' }}>
                    <div className='r-c'>
                        <center style={{ display: 'flex', flexDirection: 'row', width: '5%', justifyContent: 'space-between', marginLeft: '5.5%', marginTop: 50 }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </center>
                    </div>

                    <Row>
                        <div className='title-700 m-l-20 m-t-40' style={{ width: '100%', }}>Covid pandemic shows online learning is here for good: the mental barriers must go</div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p1}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Knowledge
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p2}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            The presence of mind
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p3}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Charisma
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p4}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Camera Presence
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p5}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Understanding the online community
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p6}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Influence
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-10'>
                            <div className='subtitle-500'>{p7}</div>
                        </div>

                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-10'>
                            <div className='subtitle-500'>{p8}</div>
                        </div>

                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-10'>
                            <div className='subtitle-500'>{p9}</div>
                        </div>

                      
                    </Row>
                </Col>
            </Hidden>
        </div>
    )
}

const Blog2 = ({ setView }) => {

    let p1 = `An influencer is a loose term in today's time that can be anything for anyone, but at the end of the day, it is supposed to be someone who can influence or dominate the mindset of a large number of people around the world. The way we talk about the positive and negative influence that people have on one another, the scale on which an influencer online does it is relatively high. From fashion influencer to social media influencer, they may play a key role in shaping the experience that people have online. Each influencer has a role to play in the online universe that has been created organically over time. However, in the past two years, one specific category of influencer has played a vital role. Many types of differentiated references have been made when talking about influencer, yet people can say that they love to hate influencer. Over time, a genre of influencer is considered one that is the most respected and is considered one that can make the biggest impact if taken into consideration as one that can create a change for the better. Educational influencer are
    the ones who have the potential to make a monumental change in the lives of many.
    The pandemic brought forth a world where people remained clueless as to how they
    could attain certain services, which included education, which is the foundation of the
    society that people live in.
    
    An educational influencer shares information that can be helpful to millions
    and has the ability to play the role of a teacher, but instead of creating face-to-face
    communication, the influencer creates communication online. Many institutions and
    organizations came across and uploaded videos of their teachers online so that
    students could gain knowledge online when they were unable to do so offline.
    
    However, in the midst of this, an opportunity arose for those who are not affiliated
    with any such institution but are capable teachers and possess the qualities required to
    be a good educational influencer.The following work will focus on understanding and
    analyzing what it takes to be an educational influencer.`

    let p2 = `Above anything essential, in an educational influencer, one factor that makes
    them stand out is their knowledge. They have a focus on specific subjects that are the
    key subjects on which they focus in all of their content in general spaces.Yet having a
    better understanding of many other factors is one that counts. A good educator needs
    to have a certain level of knowledge, yet an influencer is the one who needs to have a
    sense of seasonality in the way they handle a class. Most of these classes are live, and
    many students ask a variety of questions, which can be tricky at times and may not go
    as planned.Having proper knowledge means being able to answer all aspects of the
    class with confidence and understanding.`

    let p3 = `Apart from having a good understanding of the subject that one is working on, it
    is also a priority for an educational influencer to have a presence of mind. The
    confidence to deal with all sorts of unnoticed instances in the middle of a situation is
    what makes the following person influential.An example can be a technical glitch that
    is unavoidable, but for an educational influencer, there should be an understanding of
    how they will tackle such a situation.`

    let p4 = `Charisma in people remains something that is not calculated based on a person&#39;s
    appearance but on their personality. The way they carry themselves and the way they
    communicate with others is often connected to charisma, which is mental
    attractiveness, and charm, which is extremely easy to fake but rare to own naturally.
    Whether one owns that naturally or creates it, there needs to be an integral importance
    to the same, which has been a strength for many in life. For influencer, charisma is
    important, but for educational influencer, it binds the attention of the students who
    view their content. Charisma is important and a key requirement for all educational
    influencer. In long-duration videos, this charisma needs to be more sharp and long-
    lasting in terms of attracting the attention of those who view this content.`

    let p5 = `Many people can showcase a strong personality, but rarely do we see people
    who can channel that particular confidence in front of the camera. It is not only how
    people behave, but also how the cameras capture the person in front of them.This is
    one of the reasons people who are in front of the camera are often considered to be
    good enough based on their body language, expressions, and ease in front of the
    camera, which changes from person to person. But for an educational influencer, ease
    and camera presence are necessities that need to be developed over time. The main
    reason behind that comes from the fact that educational influencer spend a higher
    amount of time online in front of the camera.`

    let p6 = `The online community has its own choices and preferences while having a
    stronger perception of things. In the midst of the same, there comes a space where
    online creators have to make content that is more suitable to the needs and choices of
    the genre they work in. Such is also the case of educational influencer, who are
    overall a community and who have the biggest responsibility to make content and the
    methods of creating it based on the choices of the viewers. The activities that must be
    differentiated and created with proper understanding range from choosing the type of
    content to the way the content is presented, as well as the way the influencer shall
    behave.`

    let p7 = `The word &quot;influence&quot; comes from the term &quot;influencer,&quot; yet its effect and
    impact on the role are small in today's time. However, for educational influencer, the
    meaning and necessity of implementing influence had to be a priority.The best way to
    understand this is that a tutor or educator will continue to play the role of someone
    who can teach many things to those who look up to them.This is one of the reasons
    why teachers in educational institutions are in top form. Other than that, the idea of
    influence online goes beyond the subjects and also teaches life and a way to execute
    specific activities in a particular manner, which makes the job of an educational
    influencer more of a responsibility.
    
    Being an educational influencer requires a lot of factors to consider, yet at the
    end of the day, the following job holds a sense of responsibility for sharing knowledge
    online, which makes it an extremely important profession online.`

    return (

        <div>
            <Visible sm md lg xl xxl>
                <div style={{ position: 'relative' }}>
                    <img src={img2} alt='' style={{ width: 1347, height: '300px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', top: 20, cursor: 'pointer' }} src={back} />
                </div>
                <Row style={{ width: '103%' }}>
                    <div className='r-c'>
                        <div className='subtitle-700 font-700' style={{ marginLeft: 159, marginTop: 59, marginBottom: 32 }}>Share</div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '5%', justifyContent: 'space-between', marginLeft: '80.5%' }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </div>
                    </div>

                    <Col>
                        <div className='title-700 m-l-50 m-t-40' style={{ width: '100%', }}>What it takes to be an educational influencer</div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p1}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-20'>
                            Knowledge
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p2}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-20'>
                            The presence of mind
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p3}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-20'>
                            Charisma
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p4}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-20'>
                            Camera Presence
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p5}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-20'>
                            Understanding the online community
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p6}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-20'>
                            Influence
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p7}</div>
                        </div>
                    </Col>
                </Row>
            </Visible>

            <Hidden sm md lg xl xxl xxxl>
                <div style={{ position: 'relative' }}>
                    <img src={img2} alt='' style={{ width: 470, height: '200px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', left: 0, top: 10, cursor: 'pointer' }} src={back} />
                </div>
                <Col style={{ width: '103%' }}>
                    <div className='r-c'>
                        <center style={{ display: 'flex', flexDirection: 'row', width: '5%', justifyContent: 'space-between', marginLeft: '5.5%', marginTop: 50 }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </center>
                    </div>

                    <Row>
                        <div className='title-700 m-l-20 m-t-40' style={{ width: '100%', }}>What it takes to be an educational influencer</div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p1}</div>
                        </div>

                        <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Knowledge
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p2}</div>
                        </div>

                        <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            The presence of mind
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p3}</div>
                        </div>

                        <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Charisma
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p4}</div>
                        </div>

                        <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Camera Presence
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p5}</div>
                        </div>

                        <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Understanding the online community
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p6}</div>
                        </div>

                        <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Influence
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-10'>
                            <div className='subtitle-500'>{p7}</div>
                        </div>
                    </Row>
                </Col>
            </Hidden>
        </div>
    )
}

const Blog3 = ({ setView }) => {

    let p1 = `Online education, even in the current era, faces a certain level of backlash. For
    many, it is still a debatable subject whether the online world has helped society or
    made us lazier and less productive. A few years ago, this feeling was even stronger
    among many, but it diminished as the pandemic made online activities more of a
    necessity, highlighting their positive effects. Yet the factors of online abilities are still
    considered more relevant for working individuals or for people who need certain
    services that can make their day-to-day lives easier.`

    let p2 = `In the field of education, however, the idea of using online platforms has taken
    over. It may have been due to the need to keep children safe, but it has highlighted the
    positive effects that it can bring if used effectively and in the right manner. Among the
    different age groups of students, the one that is considered to be better suited for
    online education in most cases happens to be the pre-teenagers or adults; however,
    with better efficiency, it has been the younger children who range from the age group
    of 3–11 who have the most positive impact among all other age groups. The following
    work discusses the 10 reasons why e-learning is more impactful on younger students.`

    let p3 = `For younger children, one of the bigger issues inside classrooms is keeping up.
    No two children have a similar pattern of learning, while all have different strengths
    and weaknesses and intellectual capabilities, which makes all humans unique from
    one another. Online classes in most cases have the feature and the ability to record the
    classes that students attend. This not only helps students revise but also take their own
    time in learning something that may not have been understood in the first explanation
    within the classroom. The pace at which the learning will happen becomes the
    decision of the child and even the teacher, as they can repeat or even give away the
    recorded class to students who need it.`

    let p4 = `Similar to the idea of creating a method of self-paced learning, the idea of online
    education gives all students the option to choose newer ways of learning. For many
    teachers, online reality also allows them to choose different ways of learning from
    what is available offline. This can be a pictorial method or a sensory evaluation
    method, which depends not only on the teacher but also on the children who are part
    of the following class. In today's era, new methods of learning are being invented as it
    has transformed into the need of the hour, which further makes online learning the
    best way to experiment with newer techniques on children of a younger age, which
    can be carried on even in the upcoming future if the particular style of learning is well
    suited to the following person.`

    let p5 = `Among younger children, one of the most important factors to consider is
    focusing on a way of learning that improves and enriches their sensory organs.
    Learning and the various learning practices are often connected as something that can
    help improve learning as well as make the sensory organs function better. Using
    online learning methods engages in the smart method of learning that not only uses
    eyes and ears but also engages with them in a way that makes the information more
    lasting.`

    let p6 = `Apart from the different learning styles, the other ways that can make the
    learning process different are the ways children engage with teachers. There is a
    stigma that makes teachers less respected and more of a figure that is feared. Online
    classrooms create a layout that helps create an environment where teachers can create
    better relationships, and with better communication and more personalized
    relationships, the chances of them being more connected are better. This directly helps
    create a better understanding and performance among students.`

    let p7 = `From experimenting with different styles of learning, online education provides
    the opportunity for all tutors to create a new method of learning all by themselves.
    Similar to the understanding that learning and the understanding of all children are
    different from one another, there is a need to create a more personalized learning style
    for all students. Online education gives this luxury to the teachers, who can create
    new methods for all tutors individually.`

    let p8 = `Assessment of tests and basic performance is a time-consuming process that
    often is not done in the right pattern that it should, while also being the most
    exhausting part of the job profile for the teachers. But with the help of online teaching
    methods, assessments can become more than just a time-consuming process; they can
    be considered a do-nothing activity that is instant.`

    let p9 = `Geographical barriers are one of the reasons why many students are unable to gain
    a good education. In today's world, quality education is only available in bigger cities
    or urban areas, which is why people travel to different parts of the world to get quality
    education. But with the use of online learning and the proper availability of the
    internet, the need to travel is reduced. Anyone from any location can gain an
    education.`

    let p10 = `Education and its many barriers are some of the reasons why many people are not
    able to gain an education. However, barriers as common as language and monetary
    difficulties are often tackled through the use of online learning.`

    let p11 = `In the world that we live in, no human is unaware of the power of the internet, yet
    the use of the same is not patterned towards doing things that can be advantageous to
    them but rather something more focused on their benefit. In such a case, the best
    course of action is to learn about computers and the internet at large and be introduced
    to them through education. This not only makes it more productive but also positive
    in nature.`

    let p12 = `Communication between teachers and students remains the most important form
    of communication in the process of gaining an education. Yet, the overall impact of
    education on younger students requires communication between teachers and parents
    to be established for extra help. However, this communication happens at longer
    intervals and is often not relevant to making the educational experience positive. The
    use of online learning makes the same easier and more frequent, based on the needs of
    the teachers and the parents.`

    return (
        <div>
            <Visible sm md lg xl xxl>
                <div style={{ position: 'relative' }}>
                    <img src={img3} alt='' style={{ width: 1347, height: '300px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', top: 20, cursor: 'pointer' }} src={back} />
                </div>
                <Row style={{ width: '103%' }}>
                    <div className='r-c'>
                        <div className='subtitle-700 font-700' style={{ marginLeft: 159, marginTop: 59, marginBottom: 32 }}>Share</div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '5%', justifyContent: 'space-between', marginLeft: '80.5%' }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </div>
                    </div>

                    <Col>
                        <div className='title-700 m-l-50 m-t-40' style={{ width: '100%', }}>10 reasons why e-learning is more impactful on younger students</div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p1}</div>
                        </div>

                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p2}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            1. It is self-paced.
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p3}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            2. A self-selecting method of learning
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p4}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            3. Utilizes all sensory organs
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p5}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            4. A more creative way of connecting
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p6}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            5. Encourage new learning styles
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p7}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            6. Real-time assessment
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p8}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            7. No geographical barriers
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p9}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            8. A convenient method of education
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p10}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            9. It enhances computer and internet proficiency
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p11}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            10. Creating a more efficient three-way communication
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p12}</div>
                        </div>
                    </Col>
                </Row>
            </Visible>

            <Hidden sm md lg xl xxl xxxl>
                <div style={{ position: 'relative' }}>
                    <img src={img3} alt='' style={{ width: 470, height: '200px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', left: 0, top: 10, cursor: 'pointer' }} src={back} />
                </div>
                <Col style={{ width: '103%' }}>
                    <div className='r-c'>
                        <center style={{ display: 'flex', flexDirection: 'row', width: '5%', justifyContent: 'space-between', marginLeft: '5.5%', marginTop: 50 }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </center>
                    </div>

                    <Row>
                        <div className='title-700 m-l-20 m-t-40' style={{ width: '100%', }}>10 reasons why e-learning is more impactful on younger students</div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'> {p1}</div>
                        </div>

                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p2}</div>
                        </div>

                        <div className='title-700 m-l-20 m-t-40'>
                            1. It is self-paced.
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p3}</div>
                        </div>

                        <div className='title-700 m-l-20 m-t-40'>
                            2. A self-selecting method of learning
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p4}</div>
                        </div>

                        <div className='title-700 m-l-20 m-t-40'>
                            3. Utilizes all sensory organs
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p5}</div>
                        </div>

                        <div className='title-700 m-l-20 m-t-40'>
                            4. A more creative way of connecting
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p6}</div>
                        </div>

                        <div className='title-700 m-l-20 m-t-40'>
                            5. Encourage new learning styles
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p7}</div>
                        </div>

                        <div className='title-700 m-l-20 m-t-40'>
                            6. Real-time assessment
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p8}</div>
                        </div>

                        <div className='title-700 m-l-20 m-t-40'>
                            7. No geographical barriers
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p9}</div>
                        </div>

                        <div className='title-700 m-l-20 m-t-40'>
                            8. A convenient method of education
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p10}</div>
                        </div>

                        <div className='title-700 m-l-20 m-t-40'>
                            9. It enhances computer and internet proficiency
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p11}</div>
                        </div>

                        <div className='title-700 m-l-20 m-t-40'>
                            10. Creating a more efficient three-way communication
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div v>{p12}</div>
                        </div>
                    </Row>
                </Col>
            </Hidden>
        </div>
    )
}

const Blog4 = ({setView}) => {

    let p1 = `The year 2020 and the Covid- 19 pandemic have been nothing less than a nemesis for all mankind, but they have made one thing abundantly clear. Technology is the only answer to all our current miseries, constrictions and incompetency. While every soul on earth, every business, every little activity has suffered equally, as a citizen of humanity, it is the future of education that worries me the most.`

    let p2 = `The reason? Physical presence of students and teachers inside a classroom on a regular basis isn’t a certainty anymore. Luckily, that is also not the only option anymore. Unluckily, the alternatives provided by Information and Communication Technologies are not completely ready and nor are the teachers and students who are supposed to use them. In fact, technology, as presently utilised in schools and colleges, is more of a problem than a solution, especially in India.`

    let p3 = `Not every Indian teacher or student is adept at using computers and smartphones. Hundreds and thousands of students do not even possess smartphones. For those who do, there are additional issues of connectivity. Some believe that body language of students can’t be understood, and interactive activities can’t be arranged online, because of which communication sessions are ineffective. Then there is a problem of lack of attention when it comes to young kids because there is no one to check on them. While the list of technical, financial, logistical and geographical reasons why teachers (barring a small fraction of those belonging to high-end schools situated in urban areas) are struggling to deliver the goods in the online medium is endless, it is the adoption and acceptance in the mind that seems to be the bigger hurdle.`

    let p4 = `This poses another question – Does the average human mind react similarly to all kinds of change, especially, when it’s sudden? Not too long ago, when concepts like e-commerce and online retailing had been introduced, they were greeted by consumer responses like – “It’s not a real store’’, ‘‘they’ll misuse all our data’’ and “what about credit card theft?” and other negative responses. Today, here we are with some of the biggest e-commerce giants ruling the roost worldwide. Even regular meals that could easily have been prepared in our kitchens today are being ordered online.`

    let p5 = `Going further back in time, the telephone was at first criticized for ‘invasion’ of privacy! Not just that, Guglielmo Marconi, inventor of radio, doubting his own invention once said, “Have I done the world good, or have I added a menace?” And to top it all, ancient Greeks, it is said, were afraid of something as fundamental to the human mind as writing because it would produce forgetfulness, Socrates believed.`

    let p6 = `So basically, it is a historically established fact that no change, however sensible, that eventually got adopted and accepted by society, was initially welcomed by those who lived through the transition. Very similar is the condition of humans in this post pandemic world. We have newer, advanced and ever evolving communication technologies, but we’re either afraid of futuristic advancements, or just unsure about whether the change will bring about more productivity in anything that we do.
    `
    let p7 = `Yes, it’s hard to understand the notion of leaving behind the conventional classroom, especially if it’s to face this vast space called the Internet. Does this mean that there is no hope? Absolutely not. A lot will change as community online teaching emerges as an option worldwide. An infrastructure that can cater to the needs of students and teachers in the remotest corners of the country will not only address technical gaps but will also produce acceptability. Stakeholders, particularly students, are sceptical because they aren’t confident that online services will be accessible to one and all. That confidence must be built through preparation and inclusiveness.`

    let p8 = `Every student/teacher must feel that they belong. Everyone must feel connected, empowered. Online systems are not only required for providing additional academic support upon return of normalcy, but also for conducting regular classes in case of similar emergencies in the future. As online teaching grows in relevance and acceptance and stakeholders get increasingly familiar with the medium, we might witness an emphatic emergence of centralized teaching systems across the entire country. Once this idea grows, students and teachers will have more flexibility, more options to choose from, ability to access classrooms from anywhere in the world and that will definitely be more time and cost effective in comparison to traditional methods of imparting education.`

    let p9 = `In conclusion, I want to re-iterate that the mental transformation from a familiar and time-tested teaching methodology to a whole new virtual world of teaching is not going to be easy; nor was it meant to be. But with the luxury of leveraging technology in education suddenly becoming a necessity, the writing is on the wall.`

    
    return( 
<div>
            <Visible sm md lg xl xxl>
                <div style={{ position: 'relative' }}>
                    <img src={img4} alt='' style={{ width: 1347, height: '300px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', top: 20, cursor: 'pointer' }} src={back} />
                </div>
                <Row style={{ width: '103%' }}>
                    <div className='r-c'>
                        <div className='subtitle-700 font-700' style={{ marginLeft: 159, marginTop: 59, marginBottom: 32 }}>Share</div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '5%', justifyContent: 'space-between', marginLeft: '80.5%' }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </div>
                    </div>

                    <Col>
                    <div className='title-700 m-l-20 m-t-40' style={{ width: '100%', }}>What it takes to be an educational influencer</div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p1}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Knowledge
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p2}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            The presence of mind
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p3}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Charisma
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p4}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Camera Presence
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p5}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Understanding the online community
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p6}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Influence
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-10'>
                            <div className='subtitle-500'>{p7}</div>
                        </div>

                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-10'>
                            <div className='subtitle-500'>{p8}</div>
                        </div>

                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-10'>
                            <div className='subtitle-500'>{p9}</div>
                        </div>

                    </Col>
                </Row>
            </Visible>

            <Hidden sm md lg xl xxl xxxl>
                <div style={{ position: 'relative' }}>
                    <img src={img4} alt='' style={{ width: 470, height: '200px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', left: 0, top: 10, cursor: 'pointer' }} src={back} />
                </div>
                <Col style={{ width: '103%' }}>
                    <div className='r-c'>
                        <center style={{ display: 'flex', flexDirection: 'row', width: '5%', justifyContent: 'space-between', marginLeft: '5.5%', marginTop: 50 }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </center>
                    </div>

                    <Row>
                        <div className='title-700 m-l-20 m-t-40' style={{ width: '100%', }}>What it takes to be an educational influencer</div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p1}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Knowledge
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p2}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            The presence of mind
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p3}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Charisma
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p4}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Camera Presence
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p5}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Understanding the online community
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p6}</div>
                        </div>

                        {/* <div style={{ width: '90%' }} className='title-700 m-l-20 m-t-40'>
                            Influence
                        </div> */}
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-10'>
                            <div className='subtitle-500'>{p7}</div>
                        </div>

                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-10'>
                            <div className='subtitle-500'>{p8}</div>
                        </div>

                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-10'>
                            <div className='subtitle-500'>{p9}</div>
                        </div>

                    </Row>
                </Col>
            </Hidden>
        </div>
    )
}

const Blog5 = ({setView}) => {

    let p1 = `The past teaches us the lessons that strengthen our future. COVID-19 turned out to be an unexpected lesson. The idea of working from home, which was once reserved for emergencies, become everyday life for many. The pressure of maintaining a life that with not only survival but normalcy was the priority for all. Amid this confusion, the need to assure that everything remained the same after the pandemic was over was also a priority. Among other things, the focus on children’s future led to the development of the concept of online education, which was new, fragile, and underdeveloped to say the least in the year 2020. But things turned fast, and online education became the need of the hour and a reality for the field of education.`

    let p2 = `This became the first chapter that, over the years, turned itself into the fastest-growing market in the world during the pandemic. From the evolution of ed-tech to the rise of e-learning, we live in a world now after 2 years that has chosen online learning over the physical space of education. However, it was a journey that became a struggle for all. Today we will discuss some of the struggles that came along with the change of the hour.`

    let p3 = `The earlier strategies of the pandemic led to an abrupt halt in the education system around the world. With no infrastructure in hand to support and provide constant support to continue education, there can be a brief period of a few months where academic development is halted. However, with the adaptation of live meetings online, many schools and other educational institutions have turned to online classes as a way to establish education and its flow. For many students in the early stages of online learning, the biggest challenge remained a lack of understanding. The other struggle was the lack of resources well suited for students to interact and engage in online classes. More than 36% of the total population suffered due to a lack of internet support, while the number remains to be even poorer in terms of other resources for even adult students. The sheer idea of uncertainty became the biggest struggle that not only made students demotivated but also led them to have a lack of direction, which was ultimately reflected in many instances where students were reported to have made the least amount of progress academically, which in its way is a noted record.`

    let p4 = `E-Education has evolved the idea of education for not only society and its reach-ability to education has grown to better levels. However, in the initial stages of digitization, the group that primarily felt the impact of the change happened to be the teaching faculty. Teaching, which is often considered a field that had its origin in the early decades as one of the most advantageous options in terms of professional advantage, has quickly transformed into a field that has a higher percentage of people over the age of 50 who are either continuing in this field for decades or have shifted fields after working in a different profession. This makes them not only vulnerable to the following change, but also the category of people in the E-education cycle that requires the most attention. Teachers in the early stages of the conversion faced the difficulty of not being able to keep up with the technology. Most teachers were either people who did not interact well with technology or were people who had no connection to technology at all due to the nature of their job role, which is more external and fully offline. Another apparent challenge was the stress of ensuring focus or interest in the classes. Face-to-face education was an easy path where teachers were able to note the activities of each student at once. Online classrooms were a different path where each student’s conduct was conducted. It was during this period that many students interacted with the idea of creating parodies that made fun of teachers during online classes globally, which painted a picture of the difficulty that many students faced during the transition to e-learning.`

    let p5 = `Technology and adaption of the same remained an issue for the initial stages of change, yet the bigger problem was the lack of technological advantage that was slowly being solved. One of the most common examples is online attendance. E-Education is currently at a stage where it is constantly evolving and progressing to meet the changing needs. Yet the challenge remains to find the right changes that will ultimately create a time where education will not be a hindrance for all. The limitations and primary struggles of technology also further the idea that it is not accessible to all, which ultimately makes it a luxury. The fact that technology is still considered a luxury in the world that we live in presents an opportunity that shows how far the world and the specific world of e-education have to go to create a better future for itself.

    `

    let p6 = `The struggles and challenges open up a path for the future. This process of struggling to learn and understand something new and inventive is a necessity that will be the first oath for the pioneer of the education field to reach a place where the future will provide a better option for all. The education industry at present is experiencing one of the fastest shifts, which purely indicates a future where education will be available to all and not a luxury.`

    return( 
        <div>
            <Visible sm md lg xl xxl>
                <div style={{ position: 'relative' }}>
                    <img src={img6} alt='' style={{ width: 1347, height: '300px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', top: 20, cursor: 'pointer' }} src={back} />
                </div>
                <Row style={{ width: '103%' }}>
                    <div className='r-c'>
                        <div className='subtitle-700 font-700' style={{ marginLeft: 159, marginTop: 59, marginBottom: 32 }}>Share</div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '5%', justifyContent: 'space-between', marginLeft: '80.5%' }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </div>
                    </div>

                    <Col>
                        <div className='title-700 m-l-20 m-t-40' style={{ width: '100%', }}>How Adapting E-education became essential for the teaching industry.</div>
                        <div className='big-subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p1}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p2}</div>
                        </div>
                        <div className='title-700 m-l-20 m-t-40'>
                            How things changed for students
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p3}</div>
                        </div>
                        <div className='title-700 m-l-20 m-t-40'>
                            The change from the traditionalist method of teaching
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p4}</div>
                        </div>
                        <div className='title-700 m-l-20 m-t-40'>
                            Technological challenges
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p5}</div>
                        </div>
                        <div className='title-700 m-l-20 m-t-40'>
                            The future
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p6}</div>
                        </div>
                    </Col>
                </Row>
            </Visible>

            <Hidden sm md lg xl xxl xxxl>
                <div style={{ position: 'relative' }}>
                    <img src={img6} alt='' style={{ width: 470, height: '200px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', left: 0, top: 10, cursor: 'pointer' }} src={back} />
                </div>
                <Col style={{ width: '103%' }}>
                    <div className='r-c'>
                        <center style={{ display: 'flex', flexDirection: 'row', width: '5%', justifyContent: 'space-between', marginLeft: '5.5%', marginTop: 50 }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </center>
                    </div>

                    <Col>
                        <div className='title-700 m-l-20 m-t-40' style={{ width: '100%', }}>How Adapting E-education became essential for the teaching industry.</div>
                        <div className='big-subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p1}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p2}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p3}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p4}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p5}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p6}</div>
                        </div>
                    </Col>
                </Col>
            </Hidden>
        </div>
    )
}

const Blog6 = ({ setView }) => {

    let p1 = `Could anyone ever imagine online education having the ability to not only
    change the face of education but also be a path-breaking way of life that helps save
    the planet? With every different change in the field of education, no one would have
    speculated that there would come a day when learning could be done in any part of
    the planet. In Indian mythology, Gurukuls in the context of education are noted as the
    oldest part of history that talks about the origin of education. It took people days and
    even months to reach the places where they could learn special skills and knowledge.
    Today, in the 21st century, online education has changed. In the digital world that we
    live in, more than 77% of prime educational leaders prefer and believe in the
    supremacy of online learning, while also holding similar impacts over students.`

    let p2 = `From learning using external methods like books and dictionaries to relying
    solely on online media, students are confidently learning and using online learning
    methods to get better and more updated data. The boundaries and differences in
    communication between teachers, students, and even students have been reduced with
    better mediums of fluid and fast communication. Online education holds the potential
    to break many more barriers than one can contemplate. It starts with increasing a
    positive level of transparency between teachers and students, as well as parents in
    many cases. The process of online education has created the possibility of clear and
    fast feedback among teachers and parents.`

    let p3 = `E-learning is not limited to requiring both educators and students to travel to a
    specific location, which not only creates better possibilities for those who
    demographically are often unable to get a better education in an accessible way.
    Reduction of this specific barrier also eliminates the requirement for resources, which
    is often a much-needed requirement. Every piece of information and the path to
    knowledge is just a login away for everyone.`

    let p4 = `E-learning, when learned and understood, can be suited to all those who are
    experiencing it. From being highly customization to being one of the best methods of
    gaining and constantly developing content. Digital evolution plays a vital role in
    creating this scenario where many changes in how content can be analyzed, discussed,
    and shared.`

    let p5 = `Even if live classes are kept in check to be based on time, most pieces of
    information and many classes that are recorded are also accessible even after classes
    are concluded, giving students the ability to experience education and knowledge at
    the focus of their convenience.`

    let p6 = `In the past, education and the basic structure it was built on were based on books
    that remained the same for decades and had minimal changes in the newer editions.
    Many discoveries and changes have been updated in academics after decades.
    However, with online education in the picture, the content of educational courses is
    developed and updated regularly. This is more prominent because in most online
    educational institutions, the books and notes are e-books and online materials that can
    be updated every other day.`

    let p7 = `Offline education and important messages that teachers or educators wish to share
    often get lost in translation or miscommunication. However, in terms of online
    education, the situation remains different; important information is stored and can be
    accessed after a longer period of time, so that all can access it in time.`

    let p8 = `Online education in its own right is not only beneficial in terms of its quality, but
    can indirectly help reduce pollution that happens when people travel from one place
    to another to receive an education. On the other hand, replacing paper with e-notes is
    one way that has reduced the relevant amount of use of paper that is created by
    chopping down trees. Further breaking the barrier of hurting the environment.`

    let p9 = `As a barrier, adapting to the online medium of education has been a real struggle
    for many, yet once acquired with better understanding and knowledge, it has the
    potential to open up newer doors for those who have not explored the path of the
    online world, which can bring in better and newer changes for many.`

    let p10 = `Education has always been connected with the idea of being something restrictive
    and boring for all students. Yet online education opens up a path that makes it even
    more interesting and entertaining, and the possibilities are limitless if one wishes to
    make the right changes.`

    let p11 = `The barriers that were once created towards the field of education and its
    ancient ideas are slowly changing as online education is breaking barriers towards a
    better future.`


    return (
        <div>
            <Visible sm md lg xl xxl>
                <div style={{ position: 'relative' }}>
                    <img src={img6} alt='' style={{ width: 1347, height: '300px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', top: 20, cursor: 'pointer' }} src={back} />
                </div>
                <Row style={{ width: '103%' }}>
                    <div className='r-c'>
                        <div className='subtitle-700 font-700' style={{ marginLeft: 159, marginTop: 59, marginBottom: 32 }}>Share</div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '5%', justifyContent: 'space-between', marginLeft: '80.5%' }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </div>
                    </div>

                    <Col>
                        <div className='title-700 m-l-50 m-t-40' style={{ width: '100%', }}>How is online education breaking barriers?</div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p1}</div>
                        </div>

                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p2}</div>
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p3}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            The Advantages of E-learning
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            Online education is versatile
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p4}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            Time is not a barrier to e-learning.
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p5}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            New and updated content
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p6}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            Consistency
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p7}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            Eco-Friendly
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p8}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            Adaptability
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p9}</div>
                        </div>

                        <div className='title-700 m-l-50 m-t-40'>
                            An entertaining way to educate
                        </div>
                        <div className='big-subtitle-500 m-l-50 m-t-20'>
                            <div className='subtitle-500'>{p10}</div>
                            <div className='subtitle-500'>{p11}</div>
                        </div>
                    </Col>
                </Row>
            </Visible>

            <Hidden sm md lg xl xxl xxxl>
                <div style={{ position: 'relative' }}>
                    <img src={img6} alt='' style={{ width: 470, height: '200px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', left: 0, top: 10, cursor: 'pointer' }} src={back} />
                </div>
                <Col style={{ width: '103%' }}>
                    <div className='r-c'>
                        <center style={{ display: 'flex', flexDirection: 'row', width: '5%', justifyContent: 'space-between', marginLeft: '5.5%', marginTop: 50 }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </center>
                    </div>

                    <Col>
                        <div className='title-700 m-l-20 m-t-40' style={{ width: '100%', }}>How is online education breaking barriers?</div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div>{p1}</div>
                        </div>

                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div>{p2}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div>{p3}</div>
                        </div>

                        <div className='title-700 m-l-20 m-t-40'>
                            The Advantages of E-learning
                        </div>

                        <div className='title-700 m-l-20 m-t-20'>
                            Online education is versatile
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div>{p4}</div>
                        </div>

                        <div className='title-700 m-l-20 m-t-40'>
                            Time is not a barrier to e-learning.
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div>{p5}</div>
                        </div>

                        <div className='title-700 m-l-20 m-t-40'>
                            New and updated content
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div>{p6}</div>
                        </div>

                        <div className='title-700 m-l-20 m-t-40'>
                            Consistency
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div>{p7}</div>
                        </div>

                        <div className='title-700 m-l-20 m-t-40'>
                            Eco-Friendly
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div>{p8}</div>
                        </div>

                        <div className='title-700 m-l-20 m-t-40'>
                            Adaptability
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div>{p9}</div>
                        </div>

                        <div className='title-700 m-l-20 m-t-40'>
                            An entertaining way to educate
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-20'>
                            <div>{p10}</div>
                            <div>{p11}</div>
                        </div>
                    </Col>
                </Col>
            </Hidden>
        </div>
    )
}

const Blog7 = ({ setView }) => {

    let p1 = `The year 2020 and the COVID-19 pandemic have been nothing less than a nemesis for all mankind, but they have made one thing abundantly clear, and that is the fact that technology is the only answer to all our current miseries, constrictions and incompetence. While every soul on earth, every business, every little activity has suffered equally, as a citizen of humanity, it is the future of education that worries me the most. `

    let p2 = `The reason? Physical presence of students and teachers inside a classroom on a regular basis isn‘t a certainty anymore.  Luckily, that is also not the only option anymore. Unluckily, the alternatives provided by Information & Communication Technologies are not completely ready and nor are the teachers and students who are supposed to use them. In fact, technology, as presently utilized in schools and colleges, is rather more problem than solution, especially in context of India.`

    let p3 = `Not every Indian teacher or student is adept at using computers and smart phones. Hundreds and thousands of students in particular do not even possess smartphones. For those who do, there are additional issues of connectivity. Some believe that body language of students can’t be understood and interactive activities can’t be arranged online, because of which communication sessions are ineffective. Then there is a problem of lack of attention when it comes to young kids because there is no one to check on them. While the list of technical, financial, logistical and geographical reasons why teachers (barring a small fraction of those belonging to high-end schools situated in urban areas) are struggling to deliver the goods in the online medium is endless, it is the adoption and acceptance in the mind that seems to be the bigger hurdle. `

    let p4 = `This poses another question – Does the average human mind react similarly to all kinds of change, especially, when it’s sudden?  Not too long ago, when concepts like e-commerce and online retailing had been introduced, they were greeted by consumer responses like - ‘’It’s not a real store’’, ‘‘they’ll misuse all our data’’ and ‘’what about credit card theft?” and other ‘negative’ responses. Today, here we are with some of the biggest e-commerce giants ruling the roosts worldwide. Even regular meals that could easily have been prepared in our kitchens today are being ordered online.`

    let p5 = `Going further back in time, the telephone was at first criticized for ‘invasion’ of privacy! Not just that, Guglielmo Marconi, inventor of Radio, doubting his own invention once said, “Have I done the world good, or have I added a menace?”  And to top it all, ancient Greeks, it is said, were afraid of something as fundamental to the human mind as writing because it would produce forgetfulness, Socrates believed.`

    let p6 = `So basically, it is a historically established fact that no change, however sensible, that eventually got adopted and accepted by the society, was initially welcomed by those who lived through the transition. Very similar is the condition of the human race in this post pandemic world. We have newer, advanced and ever evolving communication technologies, but we’re either afraid of futuristic advancements, or just unsure about whether the change will bring about more productivity in anything that we do.`

    let p7 = `Yes, It’s hard to understand the notion of leaving behind the conventional classroom, especially if it's to face this vast space called the Internet. Does this mean that there is no hope? Absolutely not. A lot will change as community online teaching emerges as an option worldwide. An infrastructure that can cater to the needs of students and teachers in the remotest corners of the country will not only address technical gaps but will also produce acceptability. Stakeholders, particularly students, are skeptical because they aren’t confident that online services will be accessible to one and all. That confidence has to be built through preparation and inclusiveness.`

    let p8 = `Every student/teacher must feel that they belong. Everyone must feel connected, empowered. Online systems are not only required for providing additional academic support upon return of normalcy, but also for conducting regular classes in case of similar emergencies in the future. As online teaching grows in relevance and acceptance and stakeholders get increasingly familiar with the medium, we might witness an emphatic emergence of centralized teaching systems across the entire country. Once this idea grows, students and teachers will have more flexibility, more options to choose from, ability to access classrooms from anywhere in the world and that will definitely be more time and cost effective in comparison to traditional methods of imparting education.`

    let p9 = `In conclusion, I want to re-iterate that the mental transformation from a familiar and time tested teaching methodology to a whole new virtual world of teaching is not going to be easy; nor was it meant to be, but with the luxury of leveraging technology in education suddenly becoming a necessity, the writing is on the wall.`


    return (
        <div>
            <Visible sm md lg xl xxl>
                <div style={{ position: 'relative' }}>
                    <img src={img6} alt='' style={{ width: 1347, height: '300px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', top: 20, cursor: 'pointer' }} src={back} />
                </div>
                <Row style={{ width: '103%' }}>
                    <div className='r-c'>
                        <div className='subtitle-700 font-700' style={{ marginLeft: 159, marginTop: 59, marginBottom: 32 }}>Share</div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '5%', justifyContent: 'space-between', marginLeft: '80.5%' }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </div>
                    </div>

                    <Col>
                        <div className='title-800 m-l-20 m-t-40' style={{ width: '100%', }}>The huge merits of online teaching</div>
                        <div className='big-subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p1}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p2}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p3}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p4}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p5}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p6}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p7}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p8}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p9}</div>
                        </div>
                    </Col>
                </Row>
            </Visible>

            <Hidden sm md lg xl xxl xxxl>
                <div style={{ position: 'relative' }}>
                    <img src={img6} alt='' style={{ width: 470, height: '200px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', left: 0, top: 10, cursor: 'pointer' }} src={back} />
                </div>
                <Col style={{ width: '103%' }}>
                    <div className='r-c'>
                        <center style={{ display: 'flex', flexDirection: 'row', width: '5%', justifyContent: 'space-between', marginLeft: '5.5%', marginTop: 50 }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </center>
                    </div>

                    <Col>
                        <div className='title-700 m-l-20 m-t-40' style={{ width: '100%', }}>The huge merits of online teaching</div>
                        <div>
                            <img style={{ width: '100%' }} src={img7} />
                        </div>
                    </Col>
                </Col>
            </Hidden>
        </div>
    )
}

const Blog8 = ({ setView }) => {

    let p1 = `The ideas of change and innovation in our world, which might seem like the only constants are in reality, (mostly) contextual. Whenever we experience a time of massive transformation, our focus on ‘what needs to change’ is so high that the small matter of ‘what needs to stay the same’ often gets ignored and therefore, compromised. In education in particular, some ideas are as true and relevant even today as they were 20 years ago.`

    let p2 = `Indeed, we want to champion an educational culture that has both the desire and ability to accommodate changes, but only those that are a must and solve some purpose. Ephemeral changes are meaningless, but change in the right direction is evolution. The good thing about all evolutionary processes is that they don’t compromise on fundamentals.`

    let p3 = `The ‘teacher-student relationship’ for e.g. is as important today as it was decades ago and will remain so. Teachers that treated me as an individual and cared about me first were the ones that I wanted to focus on working harder for in class, and outside. Use of technology, therefore, would only make sense when it doesn’t hamper this bond, but instead allow us to build even better relationships than before. This is not to say that ‘face-to-face’ isn’t important anymore (it is crucial), but the use of technology should promote better face-to-face connections, not worse.`

    let p4 = `Another crucial and timeless element in education is content. Earlier, a school was the only place that we went to gather knowledge. Today, information is abundant and can be gathered from so many different sources. Yet, authentic information is as vital as ever. Moreover, there has also been a desirable shift from a focus on retention to a focus on understanding and deep learning. Reliance on memory will soon become a thing of the past. However, under no circumstances will the value of content diminish, ever.`

    let p5 = `Let’s take another example of a concept that will never grow old and that is ‘lifelong-learning’. I have heard this term as long as I can remember as a student and now as a professional. What is new, are the opportunities for learning and the rate at which these opportunities are evolving. Nowadays, we start learning something new and even before we get used to it, there is a change (Hello new g-mail interface!). Lifelong-learning will always be essential, but with technology, we have to get much quicker at it, which is not a bad thing at all.`

    let p6 = `So clearly, some ideas have stood the test of time and are deathless. During times of change, such as the one we are currently experiencing, it is these ideals that technology shouldn’t dabble with and uphold`

    let p7 = `There is so much to learn from all of the great work that has been done in education over the years. When we talk of transformation, it is not to rid ourselves of the wonderful past, but to create something better with the help of it.`

    let p8 = `The recent pandemic had forced educational institutions to adopt unfamiliar technology and realign processes on the fly, with limited time to assess risks. Hence, this sudden rush to move to online platforms has been more of a problem than solution.  `

    let p9 = `The true potential of these digital innovations can be realized only if they are integrated into a single ecosystem of all facilities, thus creating an end-to-end solution capable of generating powerful and meaningful processes connecting students, teachers, owners and all other possible stakeholders of an educational institution. You can’t just create an app off your phone and say I’m going to sell this to a teacher or a student or an owner of an institute, it never works that way. It’s got to be integrable, adaptable, scalable and most importantly secure.`

    let p10 = `By being curious, collaborative, and open to new learning, we can truly inspire our students to not only be prepared for the real world but ensure that they make the world better than it is now.  `


    return (
        <div>
            <Visible sm md lg xl xxl>
                <div style={{ position: 'relative' }}>
                    <img src={img6} alt='' style={{ width: 1347, height: '300px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', top: 20, cursor: 'pointer' }} src={back} />
                </div>
                <Row style={{ width: '103%' }}>
                    <div className='r-c'>
                        <div className='subtitle-700 font-700' style={{ marginLeft: 159, marginTop: 59, marginBottom: 32 }}>Share</div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '5%', justifyContent: 'space-between', marginLeft: '80.5%' }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </div>
                    </div>

                    <Col>
                        <div className='title-800 m-l-20 m-t-40' style={{ width: '100%', }}>What hasn’t changed and never will</div>
                        <div className='big-subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p1}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p2}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p3}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p4}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p5}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p6}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p7}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p8}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p9}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p10}</div>
                        </div>
                    </Col>
                </Row>
            </Visible>

            <Hidden sm md lg xl xxl xxxl>
                <div style={{ position: 'relative' }}>
                    <img src={img6} alt='' style={{ width: 470, height: '200px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', left: 0, top: 10, cursor: 'pointer' }} src={back} />
                </div>
                <Col style={{ width: '103%' }}>
                    <div className='r-c'>
                        <center style={{ display: 'flex', flexDirection: 'row', width: '5%', justifyContent: 'space-between', marginLeft: '5.5%', marginTop: 50 }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </center>
                    </div>

                    <Col>
                        <div className='title-700 m-l-20 m-t-40' style={{ width: '100%', }}>The huge merits of online teaching</div>
                        <div>
                            <img style={{ width: '100%' }} src={img8} />
                        </div>
                    </Col>
                </Col>
            </Hidden>
        </div>
    )
}


const Blog9 = ({setView}) => {
    let p1 = ` The inability to participate in the learning flow that consists of reading and
    writing in a manner that is considered to suit the basic education system followed
    globally Not to be confused with people who have a different point of view, a learning
    disability is defined as a psychological inability to understand and learn new
    information   more   effectively.   In   recent   decades,   the   concept   of   learning   and
    understanding has been established in such a way that issues associated with
    psychological issues are surfacing with greater ease than most other health issues that
    are more physical. Even today, learning disabilities are one of the most misunderstood
    psychological issues. That reasoning stems from the notion that students do not want
    to study but do not lack the ability to learn. Not only does it lead to an increased
    number of cases where teachers choose unethical means to teach students who, in
    reality, are unable to indulge in education like other people, but in many ways, the
    issue has also surfaced as one that needs the right amount of attention. The following
    blog focuses on recognising the effects of learning disabilities and how, in the modern
    world that we live in, there is the possibility to tackle the same if the right measures
    are taken into consideration.`
  
    let p2 = `To better understand how learning disabilities are addressed by online education,
    it is necessary to understand how a learning disability affects an individual. To
    understand why one is considered to have a learning disability, the common
    symptoms include difficulty in reading as well as writing. These are some of the most
    common and basic symptoms that can be found in any person. However, another
    common factor is the inability to learn information for a longer period of time.
    Children who are not able to store information for a longer period of time have a
    higher probability of having a learning disability. Other common symptoms include a
    lack of attention during the learning process and even when people are engaged in a
    conversation. Other symptoms that are often connected to other psychological issues
    can include a lack of coordination and poor organization skills. These symptoms are
    also connected to other psychological issues, as the possibility of suffering from more
    than one psychological issue is a huge possibility among people.`
  
    let p3 = `Taking primarily into account disability in the current population and how society
    presents it, there is far more work that has to be done. The percentage of people who
    have a disability worldwide is roughly 15%. 80 percent of this population resides in
    underdeveloped nations. Among the most marginalized communities are individuals
    with disabilities. They face a variety of obstacles and are more likely to have
    unfavorable socioeconomic results. A lack of adequate support infrastructure can
    seriously impair daily living. Disability is increasingly seen as a human rights concern
    by the WHO. It stresses that people are not physically handicapped; rather, society is
    to blame. However, in recent years, the impact and the experiences of people
    suffering from learning disabilities have been far more different. One of the primary
    reasons is the increased awareness of what a learning disability is all about.`
  
    let p4 = `With technological advancement, the occurrence and number of cases where
    learning disabilities are addressed have grown more than they ever could. Among all
    the different technological progressions, the biggest impacts can be seen in the growth
    of online education and the general need for it that was raised after the pandemic,
    making it a necessity and not just an option.`
  
    let p5 = `Online education can reduce the impact of learning disabilities in many ways, as it
    creates a shield that is often necessary for someone who suffers from a lack of balance
    in their educational cycle. These reasons can often be social, physical, psychological,
    or even technical in nature.`
  
    let p6 = `Social help`
  
    let p7 = `Children who suffer from learning disabilities have a common issue that has been
    witnessed in classrooms and universities, where even if the teaching facilities
    understand and sympathise with a student who needs extra care and support in terms
    of getting an education in a classroom where all students have their individual space
    of education, some still suffer from learning disabilities. Students who are frequently
    identified as peers of students with learning disabilities are the ones who make it
    difficult for students to have a normal educational experience. In this situation, a child
    suffering from learning disabilities can remain secure as long as they are not bullied
    or kept apart from other students who are different in any way.`
  
    let p8 = `Psychological help`
  
    let p9 = `Any health issue can have a significant psychological impact on an individual. In
    terms of people suffering from learning disabilities, one of the most important aspects
    to think about is the feeling of isolation and a sense of being unable to do things
    similar to how others do them. This is one of the reasons why people suffer from
    learning disorders; people can also suffer from other psychological issues as a result
    of what they face and experience while studying in educational institutions.`
  
    let p10 = `Physical Help`
    
    let p11 =`Health issues affect people with intellectual disabilities twice as frequently. Their
    medical requirements are frequently ignored and unfulfilled. They are also more
    likely to experience additional medical issues. Physical health is essential for a person
    with a learning disability, and it is often overlooked when one has a hectic schedule.
    This can often be avoided if one creates a well-structured routine that focuses on the
    physical health of people with a physical disability. Physical health can be established
    if one can create a better balance. Online education holds the ability to create such an
    environment at home where one can focus on both education and physical health.
    Other than that, various typical exercises are suited for people who are suffering from
    learning disabilities. These exercises help reduce the impact of health issues that
    people face along with learning disorders.`

    let p12 =`Technological Help`

    let p13 =`Technology and one of its core purposes happen to be to create a world that is
    easy to live in. The same goes for people who suffer from issues like learning
    disorders. One of the issues that they face is adaptability, and with technology and its
    ever-changing scale, many of these students face a space where they are not able to
    adapt to new technologies. With the adaptability of online education, this issue
    decreases exponentially. Many of these students are motivated by a desire to learn
    more and become more educated, which is essential in today's world for everyone. As
    a result, the barrier is broken, and they become more accustomed to technology in
    general.`
    return(
        <div>
      
        <Visible sm md lg xl xxl>
                <div style={{ position: 'relative' }}>
                    <img src={img9} alt='' style={{ width: 1347, height: '300px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', top: 20, cursor: 'pointer' }} src={back} />
                </div>
                <Row style={{ width: '103%' }}>
                    <div className='r-c'>
                        <div className='subtitle-700 font-700' style={{ marginLeft: 159, marginTop: 59, marginBottom: 32 }}>Share</div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '5%', justifyContent: 'space-between', marginLeft: '80.5%' }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </div>
                    </div>

                    <Col>
                        <div className='title-800 m-l-20 m-t-40' style={{ width: '100%', }}>How online education can reduce the effects of learning disabilities</div>
                        <div className='big-subtitle-500 m-l-20 m-t-20'>
                            <div className='subtitle-500'>{p1}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p2}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p3}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p4}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p5}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p6}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p7}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p8}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p9}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p10}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p11}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p12}</div>
                        </div>
                        <div style={{ width: '90%' }} className='subtitle-500 m-l-20 m-t-40'>
                            <div className='subtitle-500'>{p13}</div>
                        </div>
                    </Col>
                </Row>
            </Visible>

            <Hidden sm md lg xl xxl xxxl>
                <div style={{ position: 'relative' }}>
                    <img src={img9} alt='' style={{ width: 470, height: '200px', objectFit: 'cover', marginLeft: -75 }} />
                    <img onClick={() => { setView(0) }} style={{ position: 'absolute', left: 0, top: 10, cursor: 'pointer' }} src={back} />
                </div>
                <Col style={{ width: '103%' }}>
                    <div className='r-c'>
                        <center style={{ display: 'flex', flexDirection: 'row', width: '5%', justifyContent: 'space-between', marginLeft: '5.5%', marginTop: 50 }}>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={facebook} alt="facebook" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={whatsapp} alt="whatsapp" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.twitter.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={twitter} alt="twitter" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.instagram.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={instagram} alt="instagram" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="https://www.linkedin.com/company/theaulasapp/" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={linkedIn} alt="linkedin" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="mailto:care@aulas.in" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={email} alt="email" style={{ width: 32, marginBottom: 20 }} />
                            </a>
                            <a href="http://www.facebook.com/theaulasapp" target="_blank" rel="noreferrer">
                                <img className='m-r-20' src={chain} alt="link" style={{ width: 32 }} />
                            </a>
                        </center>
                    </div>

                    <Col>
                        <div className='title-700 m-l-20 m-t-40' style={{ width: '100%', }}>How online education can reduce the effects of learning disabilities</div>
                        <div>
                            <img style={{ width: '100%' }} src={img9} />
                        </div>
                    </Col>
                </Col>
            </Hidden>
    </div>
    )
}

const Blog = () => {

    const [view, setView] = useState(0)

    return (
        <Container style={{ width: '90%', }}>
            {
                view == 0 ?
                    <BlogHome setView={setView} />
                :
                view == 1 ?
                    <Blog1 setView={setView} />
                :
                view == 2 ?
                    <Blog2 setView={setView} />
                :
                view == 3 ?
                    <Blog3 setView={setView} />
                :
                view == 4 ?
                    <Blog4 setView={setView} />
                :
                view == 5 ?
                    <Blog5 setView={setView} />
                : 
                view == 6 ?
                    <Blog6 setView={setView} />
                :
                view == 7 ?
                    <Blog7 setView={setView} />
                :
                view == 8 ?
                    <Blog8 setView={setView} />
                :
                view == 9 ?
                    <Blog9 setView={setView} />
                :
                null
            }
        </Container>
    )
}

export default Blog