import { Carousel } from 'antd';
import React from 'react';
import bench_image from "assets/home/bench_image.svg"
import bench_image1 from "assets/home/bench_image1.svg"
import bench_image2 from "assets/home/bench_image2.svg"





const OurLogData = () => (

    <Carousel autoplay={true}>
        <div >
            <div style={{ textAlign: "center" }}>
                <img src={bench_image} alt="" style={{ width: "100%" }} />


            </div>
            <div className='title-500 color-black m-b-10 m-t-20 font-600'>Covid pandemic shows online learning is here for good: the mental barriers must go</div>

            <div className='subtitle-500 color-grey'>The Covid-19 pandemic has made one thing abundantly clear – technology is the only answer to all our current mi...<b style={{ color: "#0779E4" }} onClick={() => window.open("https://www.scmp.com/comment/letters/article/3115865/covid-pandemic-shows-online-learning-here-good-mental-barriers-must")}>Read full article</b></div>





        </div>
        <div style={{ textAlign: "center" }}>
            <img src={bench_image1} alt="" style={{ width: "100%" }} />
            <div className='title-500 color-black m-b-10 m-t-20 font-600'>How adapting e-education is now a necessity for th...</div>
            <div className='subtitle-500 color-grey'>Online education, even in the current era, faces a certain level of backlash. For many, it is still a debatable subject whe...
                <b style={{ color: "#0779E4" }} onClick={() => window.open("https://medium.com/@aulas_/how-adapting-e-education-is-now-a-necessity-for-the-teaching-industry-c2c2723cfe37")}>Read full article</b>
            </div>



        </div>
        <div style={{ textAlign: "center" }}>
            <img src={bench_image2} alt="" />
            <div className='title-500 color-black m-b-10 m-t-20 font-600'>10 reasons why e-learning is more impactful on you...</div>

            <div className='subtitle-500 color-grey'>The past teaches us the lessons that strengthen our future. COVID-19 turned out to be an unexpected lesson. ...  </div>
            <b style={{ color: "#0779E4" }} onClick={() => window.open("https://www.thestatesman.com/opinion/luxury-now-necessity-1502936601.html")}>Read full article</b>



        </div>
    </Carousel>

);

export default OurLogData;