import { observable, action, makeObservable, computed } from "mobx";
import { message } from 'antd';
import Api from "../../services/Api";
import {CountryConverter} from "./countryConverter";
// import AppState from "../../Store";

class Store {
  supportFields = {
    name: '',
    email: '',
    phone: '',
    country: '',
    message: '',
    org: '',
  };
  supportFieldsLoader = false;


  constructor() {
      makeObservable(this, {
          supportFields: observable,
          supportFieldsLoader: observable,
          requestEnquiry: action,
      })
  }

  requestEnquiry(){
    const params = {
      name: this.supportFields.name,
      email: this.supportFields.email,
      phone: this.supportFields.phone,
      message: this.supportFields.message,
      org: this.supportFields.org,
      country: CountryConverter[this.supportFields.country]
    };
    if(this.supportFields.name === '' || this.supportFields.email === '' || this.supportFields.phone === '' || this.supportFields.message === '' || this.supportFields.org === ''){
      message.error('Please fill all the details');
      return;
    }
    
    this.supportFieldsLoader = true;
    Api.post('/api/b2b/enquiry')
    .params(params)
    .send((response, error) => {
      this.supportFieldsLoader = false;
      // if(response !== undefined){
        message.success('Request sent successfully!');
        this.supportFields.name = '';
        this.supportFields.email = '';
        this.supportFields.phone = '';
        this.supportFields.message = '';
        this.supportFields.org = '';
      // }
    });
  }
}

export default new Store();
