import React, { useState } from "react";
import CarouselFeatures from "./carousel_features";
import { Row, Col, Hidden, Visible } from "react-grid-system";
import { observer } from "mobx-react";
import Carousel from 'react-multi-carousel';
import { Container } from "components";
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import liveClassroom from "assets/features/liveClassroom.svg";
import handRaise from "assets/features/handRaise.svg";
import ic_hand_raise1 from "assets/features/ic_hand_raise1.svg";
import ic_media from "assets/features/ic_media.svg"
import ic_folder from "assets/features/ic_folder.svg"
import Group_5805 from "assets/features/Group_5805.svg"
import ic_import from "assets/features/ic_import.svg"
import ic_test from "assets/features/ic_test.svg"
import ic_create from "assets/features/ic_create.svg"
import ic_evaluation1 from "assets/features/ic_evaluation1.svg"
import ic_folder1 from "assets/features/ic_folder1.svg"
import ic_instant_payment1 from "assets/features/ic_instant_payment1.svg"
import ic_easy1 from "assets/features/ic_easy1.svg"
import ic_dashboard from "assets/features/ic_dashboard.svg"
import ic_user_management from "assets/features/ic_user_management.svg"
import ic_classrooms from "assets/features/ic_classrooms.svg"
import ic_courses from "assets/features/ic_courses.svg"
import ic_announcement from "assets/features/ic_announcement.svg"
import ic_announcement2 from "assets/features/ic_announcement2.svg"
import Vector from "assets/features/Vector.svg"
import ic_announcement3 from "assets/features/ic_announcement3.svg"
import Group_5811 from "assets/features/Group_5811.svg"
import star1 from "assets/features/star1.svg"
import Group12f1 from "assets/features/Group12f1.svg"
import ic_user_management1 from "assets/features/ic_user_management1.svg"

import schedule1_1 from "assets/features/schedule1_1.svg"

import ic_courses1_2 from "assets/features/ic_courses1_2.svg"

import ic_classrooms1_2 from "assets/features/ic_classrooms1_2.svg"



import chatBox from "assets/features/chatBox.svg";
import Rectangle_362 from "assets/features/Rectangle_362.svg";
import planning_org from "assets/features/planning_org.svg";
import communicationsupport from "assets/features/communicationsupport.svg";
import touchToTalk from "assets/features/touchToTalk.svg";
import digitalWhiteBoard from "assets/features/digitalWhiteBoard.svg";
import testPortal from "assets/features/testPortal.svg";
import liveRecording from "assets/features/liveRecording.svg";
import digitalLibrary from "assets/features/digitalLibrary.svg";
import planning from "assets/features/planning.svg";
import planningCurl from "assets/features/planningCurl.svg";
import createAssignments from "assets/features/createAssignments.svg";
import publishAssignments from "assets/features/publishAssignments.svg";
import reviewAssignments from "assets/features/reviewAssignments.svg";
import testOnline from "assets/features/testOnline.svg";
import test from "assets/features/test.svg";
import media_player1 from "assets/features/media_player1.svg";
import pdf1 from "assets/features/pdf1.svg";
import pdf2 from "assets/features/pdf2.svg";
import paymentGateway from "assets/features/paymentGateway.svg";
import studentSubmission from "assets/features/studentSubmission.svg";
import evaluationImg from "assets/features/evaluationImg.svg";
import studentReview from "assets/features/studentReview.svg";
import scheduleImg from "assets/features/scheduleImg.svg";
import attendanceImg from "assets/features/attendanceImg.svg";
import sliderIndicator from "assets/about-us/sliderIndicator.svg";
import ic_offline from "assets/features/ic_offline.svg"
import ic_broadcast from "assets/features/ic_broadcast.svg"
import ic_conference from "assets/features/ic_conference.svg"
import sliderIndicatorLight from "assets/about-us/sliderIndicatorLight.svg";
import ic_touch_to_talk1 from "assets/features/ic_touch_to_talk1.svg"
import Group_5804 from "assets/features/Group_5804.svg"
import ic_evaluation2 from "assets/features/ic_evaluation2.svg"
import students_offline_attendance1 from "assets/features/students_offline_attendance1.svg"
import leaves_details1 from "assets/features/leaves_details1.svg"
import holiday_details1 from "assets/features/holiday_details1.svg"
import geo_fence1 from "assets/features/geo_fence1.svg"
import attendance_1 from "assets/features/attendance_1.svg"
import Store from './store';
import './styles.css';
import { Collapse, notification, Tabs } from "antd";

const conference1 = 'The most advanced version of aulas Live Classroom modes with interactive features so engaging that lecture delivery is as effective as in a physical learning environment, if not better.'
const digital_library = 'Create an exquisite and comprehensive online learning pool and target desired students with ease. Control over who gets access to what always lies with you. '
const hand_raise = 'Enables students to address the classroom live (both audio and video).';
const touch_to_talk = 'For prompt, real-time responses from students.';
const offline = 'Seamless maintenance of online timetable of physical classes';
const digital_white_board = 'The very soul of the software that upholds the inviolability of conventional classrooms. In fact, it’s what  distinguishes an online classroom from an online conference room. It’s a board equipped with variable pencil thickness, digital eraser, multi-colour, undo button, common geometrical shapes, hand-written to textual Optical Character Recognition (OCR) and archivable screenshot of board work.';
const fee_management = 'All finances of your institute in a live dashboard.'
const live_recording = 'Choose to record live classes in high quality when you need.';
const test_aulas = 'In aulas, conduct all kinds of tests/examinations online with ease. Creation, answering, evaluation (auto) & publishing of test results couldn’t have been made simpler.';
const three_types = 'Create all kinds of academic content and target desired students with ease. Control over who gets access to what always lies with you.'
const folder_system = 'Segregate learning material for classrooms, subjects, chapters and even topics to administer & access content effectively. Create a pool of content without any clutter.'
const offline_videos = 'Express important course fundamentals through tailored and self-made videos by uploading them to the digital library. These Videos can be either in a MP4 or FLV format. Moreover, there are no Storage limitations for uploading youtube Videos to the Digital Library.'
const communication_support = 'Helping your students and assisting institution staffing is significant to encourage academic and organisational brilliance. The aulas care features inform your learners and workforce about all the important developments at your institution like results, holidays, events, etc. Notifications and support capabilities enable you to efficiently resolve all issues and avoid unnecessary distractions from the learning and operational objectives.'
const digital_library_white = 'Multiple screens, screenshots, and pencils with varying colors and thicknesses.';
const geo_fence = 'Locate your institute on Google maps and set a permissible radius within which associates can punch-in and punch-out from. Multiple locations are permitted.';
const leaves_details = 'Define the number of permissible casual, sick & other leaves for your institute in advance. Provides a seamless leave application and approval system.'
const holiday_details = 'Define the holiday calendar at the beginning of the academic session for all students & staff.'
const students_offline_attendance = 'Auto-marking of Live class attendance.'
const students_offline_attendance12 = 'Mark student attendance for physical classes on the app.'
const broadcast1 = 'Encapsulates most of the essentials of a conventional classroom. This  is useful for instructional purposes where only the teacher’s audibility and visibility are important.'
const attendace_Detail = 'Manage class-wise and day-wise attendance of students, teachers & others, In-out timings, holidays and leaves with ease.'
const planning_and_org = 'Enables institutes to plan, schedule and execute tasks in loop and with consummate ease. Our user-friendly tools will allow teachers and administrators to run the show and adhere to academic & management  routines in an automated fashion.'
const planning_subtitle = 'Enables institutes to plan, schedule and execute tasks in loop and with consummate ease. Our user-friendly tools will allow teachers and administrators to adhere to academic & managerial routines in an automated fashion.';
const live_subtitle = 'Transform and enhance your lecture delivery in digital classrooms without compromising the essentials of a physical learning environment. Live classes offer excellent interactivity and intuitive ways of modern-day classroom learning.';
const create_assignments = 'Let’s teachers create draft assignments and tests in their desired format- (subjective, objective and numeric). A new role of Data Operator is also provided as a teacher’s substitute for this task.';
const notification_detail = 'Keep in touch with all the developments at your institution through the notifications module. The notification categories help access notifications from various modules within a single dashboard. '
const annoucment_detail = 'Convey all significant announcements to your students and institute personnel through the announcement feature. Create and segregate announcements for different user categories to help users access them easily. '
const publish_assignments = 'Publish the draft assignments or conduct tests whenever you wish. In the case of assignments, the due date would be mentioned. Tests would be conducted on a specific date and time.';
const student_submission = 'Students can answer and submit an assignment or test from their mobile, tablet, laptop or desktop with ease.';
const evaluation_text = 'Evaluation of submissions for both assignments and tests is automatic for objective and numeric questions. Teacher only has to mark subjective answers which is seamless too.';
const schedule_text = 'Scheduling a timetable for all the classes became more straightforward with the schedule feature. You are good to go for scheduling classes by providing the start and end dates and planning classes in advance for the entire year. Of course, you can reschedule (whole or part) whenever you need. '
const attendance_text = 'Whether teacher or student, just forget about marking attendance ever again. All you need to do is show up.';
const payment_text = 'Receive and manage student fee via an integrated payment gateway which will allow the administrator to notify students about pending dues and auto-update individual payment status.';
const evalution = 'Experience a seamless evaluation of subjective tests and auto evaluation of objective type tests which makes light work of test assessments.'
const create_aulas = 'Create tests focused on different segments and sections of the syllabus in either subjective or objective patterns. Create part, full, or chapter wise tests which helps students obtain a rigorous revision of essential concepts.'
const Practice_Live = 'Offer an excellent test-taking and self-assessment experience for your students. Promote the practice of frequent student evaluator assessment with practice and live tests.  '
const folder = 'Segregate and allocate learning material for individual classrooms and courses. Take the guesswork out and help students access learning resources formulated for their courses and curriculum. '
const import_detail = 'Import sample question papers based on different subjects and syllabus. Pick relevant questions from sample question papers to include while creating tests.';
const student_review = 'All a teacher needs to do post evaluation is click on the publish result button and the students of that class will automatically receive the results with full details on their mobile phones. Pretty simple right?';
const instant_payment = 'Generate Online/Offline payment requests instantly for individual students or an entire class in a few simple clicks. '
const easy_payment = 'Offer simplified payment structure breakdowns for course fees or an Individual to suit the flexibility of all Students.   '
const course_detail = 'Add and assign courses for all the classrooms at your institute from the courses module. Make modifications and additions to your curriculum from pre-existing course structures.'
const classroom = 'Digital classrooms take the experience of a physical classroom and enhance it to provide the best classroom interaction between teachers and students.'
const user_management = 'Access your user account information and edit your account credentials with simplicity and security. Enjoy different privileges that empower you to take up additional responsibilities along with your existing user role. Approve user registration requests and add new users to your institute by utilising the manual or auto-approval user registration feature.    '
// const announcement_detail = 'Convey all significant announcements to your students and institute personnel through the announcement feature. Create and segregate announcements for different user categories to help users access them easily. '
const aulas_care = 'Get meaningful resolutions to all the student and institute workforce queries through aulas care. Aulas care connects students to institute support and institutes to aulas support separately to solve all user queries efficiently. '
const live_dash = 'Monitor and supervise the status of all institute payments under a single dashboard. Get instant updates of Payments that are Overdue, Pending, Paid, Refunded, etc on the go all under one Roof for your added convenience.'
const question_bank = 'Teachers can create innovative learning content and convey them to students with ease.'
const userManagementText = 'The most vital tool that allows you to manage your students and staff efficiently. Multiple user roles and privileges.'
const classroomsText = 'Create a digital replica of your existing classrooms.'
const coursesText = 'Create all courses available in your institute along with their fee structures.'
const scheduleText = 'Plan, schedule and conduct classes for an entire academic session in loop and with consummate ease.'


const { Panel } = Collapse;
const Row2 = ({ image, title, subtitle, location }) => (
  <div className='m-t-50'>
    <img src={image} alt="" style={{ width: "13%" }} />
    <div className='title-500 color-black m-b-10 m-t-20 font-600'>{title}</div>
    <div style={{ textAlign: "justify", width: 500, marginTop: location == 'multi' ? 30 : 0 }} className='subtitle-500 color-grey'>{subtitle}</div>
  </div>
)
const Row22 = ({ image, title, subtitle }) => (
  <div style={{ textAlign: "center" }}>
    <div style={{ textAlign: "justify", width: '80%' }} className='subtitle-500 color-grey'>{subtitle}</div>
  </div>
)
const Row14 = ({ image, title, subtitle }) => (
  <div className='m-t-50'>
    <img src={image} alt="" style={{ width: "15%" }} />


    <Collapse bordered={false} expandIconPosition="right">
      <Panel className='title-400 color-black m-b-10 m-t-10 font-600' header="Three Types Of Media" key="1">
        <div style={{ display: "flex" }}>
          <img src={star1} style={{ marginTop: "-18px", }} />

          <div style={{ marginLeft: "5px", fontSize: "13px", marginTop: "25px" }} className='color-black m-b-10 m-t-10 font-600'>VIDEOS: </div>
          <div style={{ marginTop: "25px", marginLeft: "20px" }}>
            <div className='subtitle-500 color-grey'>UNLIMITED YOUTUBE LINKS (No storage needed)</div>
            <div className='subtitle-500 color-grey'>LOCALLY UPLOAD VIDEOS (mp4, mkv, flv, avi, mov)</div>

          </div>
        </div>
        <Row1 image={pdf1} title={'LECTURE NOTES'} />
        <Row1 image={pdf2} title={'QUESTION BANK'} />
      </Panel>

    </Collapse>

  </div>
)
const Row16 = ({ image, title, subtitle }) => (
  <div className='m-t-50'>
    <img src={ic_folder} alt="" style={{ width: "15%" }} />
    <Collapse bordered={false} expandIconPosition="right">
      <Panel className='title-400 color-black m-b-10 m-t-10 font-600' header='Multi-Layered Folder system' key="1">
        <div className='subtitle-500 color-grey' style={{ fontSize: "14px" }}>Segregate learning material for classrooms, subjects, chapters and even topics to administer & access content effectively. Create a pool of content without any clutter.</div>

      </Panel>
    </Collapse>

  </div>
)




const Row12 = ({ image, title, subtitle }) => (
  <div className='m-t-20'>
    <div>
      <Visible sm md lg xl xxl xxxl>
        <img src={image} alt="" style={{ width: "15%" }} />
      </Visible>
      <Hidden sm md lg xl xxl xxxl>
        <img src={image} alt="" style={{ width: "15%" }} />
      </Hidden>
    </div>
    <div className='title-500 color-black m-b-10 m-t-20 font-600'>{title}</div>
    <div className='subtitle-500 color-grey'>{subtitle}</div>
  </div>
)

const Row10 = ({ image, title, subtitle }) => (
  <div className='m-t-50'>
    <div style={{ textAlign: "center" }}>
      <img src={image} alt="" style={{ width: "20%" }} />
    </div>
    <div style={{ textAlign: "center" }} className='title-500 color-black m-b-10 m-t-20 font-600'>{title}</div>
    <div style={{ textAlign: "center" }} className='subtitle-500 color-grey'>{subtitle}</div>
  </div>
)
const Row17 = ({ image, title, subtitle }) => (
  <div className='m-t-50'>
    <div style={{ textAlign: "center" }}>
      <Visible sm md lg xl xxl xxxl>
        <img src={image} alt="" style={{ width: "50%" }} />
      </Visible>
      <Hidden>
        <img src={image} alt="" style={{ width: "35%" }} />
      </Hidden>
    </div>
    <div style={{ fontSize: "16px", textAlign: "center" }} className='title-500 color-black m-b-10 m-t-20 font-600'>{title}</div>
    <div style={{ fontSize: "13px", textAlign: "center" }} className='subtitle-500 color-grey'>{subtitle}</div>
  </div>
)
const Row5 = ({ image, title, subtitle }) => (

  <div className='m-t-50'>
    <div style={{ textAlign: "center" }}>
      <img src={image} alt="" style={{ width: "20%" }} />
    </div>
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ fontSize: "21px", fontWeight: "bold", fontFamily: "Raleway" }} >{title}</div>
    </div>
    <div style={{ textAlign: "center", fontSize: "13px" }} className='subtitle-500 color-grey'>{subtitle}</div>
  </div >
)
const Row15 = ({ image, title, subtitle }) => (

  <div style={{ marginTop: "40px" }}>
    <div style={{ textAlign: "center" }}>
      <Visible sm md lg xl xxl xxxl>
        <img src={image} alt="" />
      </Visible>
      <Hidden sm md lg xl xxl xxxl>
        <img src={image} alt="" style={{ width: '35%' }} />
      </Hidden>
    </div>
    <div style={{ display: "flex", justifyContent: "center", }}>
      <div style={{ fontSize: "15px", fontWeight: "bold", fontFamily: "Raleway", marginTop: "15px" }} >{title}</div>
    </div>
    <div style={{ textAlign: "center", fontSize: "12px", marginTop: "15px" }} className='subtitle-500 color-grey'>{subtitle}</div>
  </div >
)
const Row1 = ({ image, title, subtitle }) => (
  <div className='m-t-10' style={{ display: "flex" }}>
    <img src={star1} style={{ marginTop: "8px", }} />

    <div className='color-black m-b-10 m-t-20 font-600' style={{ marginTop: "20px", marginLeft: "5px", fontSize: "13px" }}>{title}</div>
    <img src={image} alt="" style={{ width: "5%", marginTop: "10px", marginLeft: "10px" }} />
    <div className='subtitle-500 color-grey m-t-10'>{subtitle}</div>
  </div >

)
const Row8 = ({ title, subtitle }) => (
  <div className='m-t-10'>
    <div className='color-black m-b-10 m-t-10 font-600'>{title}</div>
    {/* <img src={image} alt="" /> */}
    <div className='subtitle-500 color-grey m-t-10'>{subtitle}</div>
  </div >

)



const Row3 = ({ image, title, subtitle, index }) => (
  <div className='m-t-50'>
    <Visible md lg xl xxl >
      <Row >
        <Col xs={12} sm={6} md={7} className='m-b-20 r-fs-c'>
          <img src={image} alt="" style={{ width: '65%' }} />
        </Col>
        <Col xs={12} sm={6} md={5} className='c-jc'>
          <div className='title-800 color-black m-b-20'>{title}</div>
          <div style={{ textAlign: "justify" }} className='subtitle-500 color-grey'>{subtitle}</div>
        </Col>
      </Row>

    </Visible>
    <Hidden md lg xl xxl >
      <Row >
        <Col xs={12} sm={6} md={7} className='m-b-20 r-fs-c' >
          <center>
            <img style={{ textAlign: "center", width: "80%" }} src={image} alt="" />
          </center>
        </Col>
        <Col xs={12} sm={6} md={5} className='c-jc'>

          <div className='title-large-800 color-black' style={{ fontSize: "1.9rem" }}>{title}</div>
          <div style={{ textAlign: "justify" }} className='subtitle-500 color-grey'>{subtitle}</div>
        </Col>
      </Row>
    </Hidden>


  </div>

)
const Row13 = ({ image, title, subtitle, index }) => (
  <Row className='m-t-50'>
    <Col xs={12} sm={6} md={7} className='m-b-20 r-fs-c'>
      <Visible sm md lg xl xxl xxxl>
        <img src={image} alt="" style={{ width: '65%' }} />
      </Visible>
      <Hidden sm md lg xl xxl xxxl>
        <center>
          <img src={image} alt="" style={{ width: '80%' }} />
        </center>
      </Hidden>
    </Col>
    <Col xs={12} sm={6} md={5} className='c-jc'>
      <Visible sm md lg xl xxl>
        <div className='title-800 color-black m-b-20'>{title}</div>
      </Visible>
      <Hidden sm md lg xl xxl xxxl>
        <div className='title-large-800 color-black' style={{ fontSize: "1.9rem" }}>{title}</div>
      </Hidden>
      {/* <div style={{ textAlign: "justify" }} className='subtitle-500 color-grey'>{subtitle}</div>
       */}
      <div style={{ display: "flex" }}>
        <img src={star1} />
        <div style={{ display: "flex" }}>
          <div style={{ marginTop: "20px", marginLeft: "20px", fontSize: "13px", width: 215 }} className='color-black m-b-10 m-t-10 font-600 m-r-10'>aulas care: </div>
          <div style={{ marginTop: "20px", fontSize: "12px" }} className='subtitle-500 color-grey'>Get your queries resolved through a dedicated support system designed for all the users in an institute.</div>

        </div>

      </div>
      <div style={{ display: "flex" }}>
        <img src={star1} />
        <div style={{ display: "flex" }}>
          <div style={{ marginTop: "20px", marginLeft: "20px", fontSize: "13px", width: 165 }} className='color-black m-b-10 m-t-10 font-600 m-r-10'>Announcements: </div>
          <div style={{ marginTop: "20px", fontSize: "12px" }} className='subtitle-500 color-grey'>Stay in the loop with tailored updates on events, deadlines, and news just for you!</div>

        </div>

      </div>
      <div style={{ display: "flex" }}>
        <img src={star1} />
       

        <div style={{ display: "flex" }}>
          <div style={{ marginTop: "20px", marginLeft: "20px", fontSize: "13px", width: 200 }} className='color-black m-b-10 m-t-10 font-600 m-r-10'>Notifications: </div>
          <div style={{ marginTop: "20px", fontSize: "12px" }} className='subtitle-500 color-grey'>  Get instant alerts for messages, assignments, and schedule changes, right when you need them.</div>

        </div>
      </div>

    </Col>
  </Row>
)
const Row9 = ({ image, title, subtitle, index }) => (
  <Row className='m-t-50'>
    <Col xs={12} sm={6} md={7} className='m-b-20 r-fs-c'>
      <Visible sm md lg xl xxl xxxl>
        <img src={image} alt="" style={{ width: '65%' }} />
      </Visible>
      <Hidden sm md lg xl xxl xxxl>
        <img src={image} alt="" className="m-l-40" style={{ width: '80%' }} />
      </Hidden>
    </Col>
    <Col xs={12} sm={6} md={5} className='c-jc'>

      <div className='title-large-800 color-black' style={{ fontSize: "1.9rem" }}>{title}</div>
      <div style={{ textAlign: "justify" }} className='subtitle-500 color-grey'>{subtitle}</div>
      <div style={{ display: "flex" }}>
        <div>
          <div style={{ display: "flex", marginTop: "13px", }}>
            <img src={star1} />
            <div className='subtitle-500 color-grey' style={{ marginLeft: "10px", marginTop: "20px" }}>Live dashboard of institute payment history</div>
          </div>
          <div style={{ display: "flex", marginTop: "13px" }}>
            <img src={star1} />
            <div className='subtitle-500 color-grey' style={{ marginLeft: "10px", }}>Create course fee structures</div>
          </div>
          <div style={{ display: "flex", marginTop: "13px" }}>
            <img src={star1} />
            <div className='subtitle-500 color-grey' style={{ marginLeft: "10px", marginTop: "20px" }}>Individual and group payment requests</div>
          </div>
          <div style={{ display: "flex", marginTop: "13px" }}>
            <img src={star1} />
            <div className='subtitle-500 color-grey' style={{ marginLeft: "10px", }}>Offline Payment Requests</div>
          </div>
          <div style={{ display: "flex", marginTop: "13px" }}>
            <img src={star1} />
            <div className='subtitle-500 color-grey' style={{ marginLeft: "10px", }}>Equal/varying Installments</div>
          </div>
        </div>

        <div >
          <div style={{ display: "flex", marginTop: "13px", }}>
            <img src={star1} />
            <div className='subtitle-500 color-grey' style={{ marginLeft: "10px", marginTop: "20px" }}>Manual & automatic payment reminders</div>
          </div>
          <div style={{ display: "flex", marginTop: "13px" }}>
            <img src={star1} />
            <div className='subtitle-500 color-grey' style={{ marginLeft: "10px", }}>Multiple payment modes</div>
          </div>
          <div style={{ display: "flex", marginTop: "13px", marginTop: "35px" }}>
            <img src={star1} />
            <div className='subtitle-500 color-grey' style={{ marginLeft: "10px", }}>Refunds</div>
          </div>
          <div style={{ display: "flex", marginTop: "13px", marginTop: "35px" }}>
            <img src={star1} />
            <div className='subtitle-500 color-grey' style={{ marginLeft: "10px", }}>Individual transaction history</div>
          </div>
        </div>
      </div>

    </Col>
  </Row>
)


const Row4 = ({ image, title, subtitle, index }) => (
  <Row className='m-t-50'>
    <Visible sm md lg xl xxl>
      <Col xs={12} sm={6} md={5} className='c-jc'>
        <div className='title-800 color-black m-b-20'>{title}</div>
        <div style={{ textAlign: "justify" }} className='subtitle-500 color-grey'>{subtitle}</div>
        <div style={{ display: "flex", marginTop: "30px" }}>
          <div>
            <div style={{ display: "flex", marginTop: "13px", }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Practice and Live tests</div>
            </div>
            <div style={{ display: "flex", marginTop: "13px" }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Multiple creator roles</div>
            </div>
            <div style={{ display: "flex", marginTop: "13px" }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Interactivity between creators</div>
            </div>
            <div style={{ display: "flex", marginTop: "13px" }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Subjective, MCQs, Numerical</div>
            </div>
            <div style={{ display: "flex", marginTop: "13px" }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Answer by touch/upload</div>
            </div>
          </div>

          <div >
            <div style={{ display: "flex", marginTop: "13px", }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Auto-evaluation</div>
            </div>
            <div style={{ display: "flex", marginTop: "13px" }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Import questions</div>
            </div>
            <div style={{ display: "flex", marginTop: "13px" }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Test cloning</div>
            </div>
            <div style={{ display: "flex", marginTop: "13px" }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Student Performance Analytics/Ranking</div>
            </div>

          </div>
        </div>


      </Col>
      <Col xs={12} sm={6} md={7} className='m-b-20 r-fe-c'>
        <img src={image} alt="" style={{ width: '65%' }} />
      </Col>

    </Visible>
    <Hidden sm md lg xl xxl>
      <Col xs={12} sm={6} md={7} className='m-b-20 r-fe-c'>
        <div className="r-c m-l-40" style={{ marginTop: -20 }}>
          <img src={image} alt="" style={{ width: '80%' }} />
        </div>

      </Col>
      <Col xs={12} sm={6} md={5} className='c-jc'>
        <div className='title-large-800 color-black' style={{ fontSize: "1.9rem" }}>{title}</div>
        <div style={{ textAlign: "justify" }} className='subtitle-500 color-grey'>{subtitle}</div>
        <div style={{ display: "flex", marginTop: "30px" }}>
          <div>
            <div style={{ display: "flex", marginTop: "13px", }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Practice and Live tests</div>
            </div>
            <div style={{ display: "flex", marginTop: "13px" }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Multiple creator roles</div>
            </div>
            <div style={{ display: "flex", marginTop: "13px" }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Interactivity between creators</div>
            </div>
            <div style={{ display: "flex", marginTop: "13px" }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Subjective, MCQs, Numerical</div>
            </div>
            <div style={{ display: "flex", marginTop: "13px" }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Answer by touch/upload</div>
            </div>
          </div>

          <div >
            <div style={{ display: "flex", marginTop: "13px", }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Auto-evaluation</div>
            </div>
            <div style={{ display: "flex", marginTop: "13px" }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Import questions</div>
            </div>
            <div style={{ display: "flex", marginTop: "13px" }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Test cloning</div>
            </div>
            <div style={{ display: "flex", marginTop: "13px" }}>
              <img src={star1} />
              <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Student Performance Analytics/Ranking</div>
            </div>

          </div>
        </div>
      </Col>
    </Hidden>
  </Row>
)
const Row11 = ({ image, title, subtitle, index }) => (
  <Row className='m-t-50'>
    <Visible sm md lg xl xxl>
      <Col xs={12} sm={6} md={5} className='c-jc'>
        <div className='title-800 color-black m-b-20'>{title}</div>
        <div style={{ textAlign: "justify" }} className='subtitle-500 color-grey'>{subtitle}</div>



      </Col>
      <Col xs={12} sm={6} md={7} className='m-b-20 r-fe-c'>
        <img src={image} alt="" style={{ width: '65%' }} />
      </Col>

    </Visible>
    <Hidden sm md lg xl xxl>
      <Col xs={12} sm={6} md={7} className='m-b-20 r-fe-c'>
        <center>
          <img src={image} alt="" style={{ width: '80%' }} />
        </center>
      </Col>
      <Col xs={12} sm={6} md={5} className='c-jc'>
        <div className='title-large-800 color-black' style={{ fontSize: "1.9rem" }}>{title}</div>
        <div style={{ textAlign: "justify" }} className='subtitle-500 color-grey'>{subtitle}</div>

      </Col>
    </Hidden>
  </Row>
)

const Features = () => {
  const [isDetail, setIsDetail] = useState(0)
  return (
    <Container>
      <Row>
        <Col xs={12} sm={6} className='c-jc'>
          <Visible sm md lg xl xxl>
            <div className='title-large-800 color-black' style={{ fontSize: "3rem" }}>Live Classrooms</div>
            {/* <div className='title-large-800 color-black m-b-10'>Classroom</div> */}
            {/* <div style={{ textAlign: "justify" }} className='subtitle-500 color-grey'>{live_subtitle}</div> */}
          </Visible>
          <Hidden sm md lg xl xxl>
            <img src={liveClassroom} alt="" className='m-b-20' style={{ width: '100%' }} />
          </Hidden>
        </Col>
        <Col xs={12} sm={6} className='c-jc'>
          <Visible sm md lg xl xxl>
            <img src={liveClassroom} alt="" style={{ width: '95%' }} />
          </Visible>
          <Hidden sm md lg xl xxl>
            <div className='title-large-800 color-black' style={{ fontSize: "1.9rem" }}>Live Classrooms</div>
            {/* <div style={{ textAlign: "justify", fontSize: "17px" }} className='color-grey'>{live_subtitle}</div> */}
          </Hidden>
        </Col>
      </Row>
      <Visible sm md lg xl xxl xxxl>
        <div className=' color-black' style={{ fontSize: "1.7rem", textAlign: "center", marginTop: "50px" }}>Varying modes of classroom environments available to suit your needs.</div>
      </Visible>

      <Hidden lg xl xxl xxxl>
        <div className='color-black title-400 m-b-20' style={{ fontSize: "1rem", textAlign: "center", marginTop: "25px" }}>Varying modes of classroom environments available to suit your needs.</div>
      </Hidden>


      <Visible md lg xl xxl>
        <div style={{ display: "flex", textAlign: "center", width: "100%", marginTop: "40px" }}>
          <div style={{ width: "20%" }}>
            <div onClick={() => {
              setIsDetail(0)
            }} className={`color-black m-b-20 ${isDetail == '0' && 'active-tab'}`} style={{ fontSize: "24px", color: "#D9D9D9", cursor: "pointer" }}>Offline</div>
            <div
              onClick={() => {
                setIsDetail(1)
              }} className={`color-black m-b-20 ${isDetail == '1' && 'active-tab '}`} style={{ fontSize: "24px", color: "#D9D9D9", cursor: "pointer" }}>Broadcast</div>
            <div
              onClick={() => {
                setIsDetail(2)
              }} className={`color-black m-b-20 display-flex ${isDetail == '2' && 'active-tab '}`} style={{ fontSize: "24px", color: "#D9D9D9", cursor: "pointer", paddingLeft: 10 }}>Conference <span className="subtitle-500 m-t-10 m-l-10">(Premium)</span></div>
          </div>
          <div style={{ borderRight: "solid 2px #D9D9D9" }}></div>

          <div style={{ marginLeft: "40px", width: "80%", textAlign: "center" }}>
            {!isDetail ?
              <div style={{ marginTop: "10px" }}>
                <Row22 subtitle={offline} />
                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div>
                    <img src={star1} />
                  </div>
                  <div className='subtitle-500 color-grey' style={{ marginLeft: "20px" }}>Online schedule of physical classes</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <img src={star1} />
                  </div>
                  <div className='subtitle-500 color-grey' style={{ marginLeft: "20px" }}>Doubt solving sessions</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <img src={star1} />
                  </div>
                  <div className='subtitle-500 color-grey' style={{ marginLeft: "20px" }}>Chat-box to share ideas and resources</div>
                </div>
                <div>
                </div>
              </div> :
              <>
                {
                  isDetail === 1 ?
                    <div>
                      <Row22 subtitle={broadcast1} />
                      <div>
                        <div style={{ display: "flex", marginTop: "20px" }}>
                          <div>
                            <img src={star1} />
                          </div>
                          <div style={{ marginLeft: "20px" }} className='subtitle-500 color-grey'>One-way visual interaction</div>

                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <img src={star1} />
                          </div>
                          <div className='subtitle-500 color-grey' style={{ marginLeft: "20px" }}> One-way verbal interaction</div>


                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <img src={star1} />
                          </div>
                          <div className='subtitle-500 color-grey' style={{ marginLeft: "20px" }}> Two-way text messaging</div>


                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <img src={star1} />
                          </div>
                          <div className='subtitle-500 color-grey' style={{ marginLeft: "20px" }}>Digital White Board (Multiple screens/Screenshot)</div>


                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <img src={star1} />
                          </div>
                          <div className='subtitle-500 color-grey ' style={{ marginLeft: "20px" }}>Screen Sharing</div>


                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <img src={star1} />
                          </div>
                          <div className='subtitle-500 color-grey' style={{ marginLeft: "20px" }}>Chat-Box (both private & group chat)</div>


                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <img src={star1} />
                          </div>
                          <div className='subtitle-500 color-grey' style={{ marginLeft: "20px" }}>File sharing and recording</div>


                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <img src={star1} />
                          </div>
                          <div className='subtitle-500 color-grey' style={{ marginLeft: "20px" }}>500 user-hours free</div>



                        </div>
                      </div>

                    </div>
                    :
                    <>
                      {
                        isDetail === 2 ?
                          <div> <Row22 subtitle={conference1} />
                            <div style={{ display: "flex", marginTop: "30px" }}>
                              <div>
                                <div style={{ display: "flex", }}>
                                  <img src={star1} />
                                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Two-way visual interaction</div>
                                </div>
                                <div style={{ display: "flex", marginTop: "13px" }}>
                                  <img src={star1} />
                                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Two-way verbal interaction</div>
                                </div>
                                <div style={{ display: "flex", marginTop: "13px" }}>
                                  <img src={star1} />
                                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Two-way text messaging</div>
                                </div>
                                <div style={{ display: "flex", marginTop: "13px" }}>
                                  <img src={star1} />
                                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Digital White Board(Multiple screens/Screenshot)</div>
                                </div>
                                <div style={{ display: "flex", marginTop: "13px" }}>
                                  <img src={star1} />
                                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Screen Sharing</div>
                                </div>
                              </div>

                              <div style={{ marginLeft: 60 }} >
                                <div style={{ display: "flex", }}>
                                  <img src={star1} />
                                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Chat-Box(Both private and group chat)</div>
                                </div>
                                <div style={{ display: "flex", marginTop: "13px" }}>
                                  <img src={star1} />
                                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>File sharing and  recording</div>
                                </div>
                                <div style={{ display: "flex", marginTop: "13px" }}>
                                  <img src={star1} />
                                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Hand-Raise</div>
                                </div>
                                <div style={{ display: "flex", marginTop: "13px" }}>
                                  <img src={star1} />
                                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Touch-to-talk</div>
                                </div>

                              </div>
                            </div></div> : null
                      }
                    </>
                }
              </>

            }




          </div>

        </div>




      </Visible>
      <Hidden md lg xl xxl>

        <Tabs defaultActiveKey="1" centered>
          <Tabs.TabPane className='title-800 color-black m-b-20' tab="Offline" key="1" >
            <Row22 subtitle={offline} />
            <div style={{ marginTop: "15px" }}>
              <ul>
                <li className='subtitle-500 color-grey'>Online schedule of physical classes</li>
                <li className='subtitle-500 color-grey'> Doubt solving sessions</li>
                <li className='subtitle-500 color-grey'> Chat box to share ideas and resources</li>
              </ul>

            </div>
          </Tabs.TabPane>
          <Tabs.TabPane className='title-800 color-black m-b-20' tab="Broadcast" key="2">
            <Row22 subtitle={broadcast1} />
            <ul>
              <li className='subtitle-500 color-grey'>Teacher-student interaction interface</li>
              <li className='subtitle-500 color-grey'> One-way video communication</li>
              <li className='subtitle-500 color-grey'> One-way audio communication</li>
              <li className='subtitle-500 color-grey'>Digital White Board -Multiple screens/Screenshot</li>
              <li className='subtitle-500 color-grey'>Screen Sharing</li>
              <li className='subtitle-500 color-grey'>Classroom Chat Box (with pvt. chat option)</li>
              <li className='subtitle-500 color-grey'>File sharing</li>
              <li className='subtitle-500 color-grey'>Recording</li>
              <li className='subtitle-500 color-grey'>Free 500 user hours</li>
            </ul>
          </Tabs.TabPane>
          <Tabs.TabPane className='title-800 color-black m-b-20' tab="Conference" key="3">
            <Row22 subtitle={conference1} />
            <div style={{ display: "flex", marginTop: "30px" }}>
              <div>
                <div style={{ display: "flex", }}>
                  <img src={star1} />
                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Two-way visual interaction</div>
                </div>
                <div style={{ display: "flex", marginTop: "13px" }}>
                  <img src={star1} />
                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Two-way verbal interaction</div>
                </div>
                <div style={{ display: "flex", marginTop: "13px" }}>
                  <img src={star1} />
                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Two-way text messaging</div>
                </div>
                <div style={{ display: "flex", marginTop: "13px" }}>
                  <img src={star1} style={{ marginTop: "-17px" }} />
                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Digital White Board(Multiple screens/Screenshot)</div>
                </div>
                <div style={{ display: "flex", marginTop: "13px" }}>
                  <img src={star1} style={{ marginTop: "-2px", }} />
                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Screen Sharing</div>
                </div>
              </div>

              <div >
                <div style={{ display: "flex", }}>
                  <img src={star1} style={{ marginTop: "-20px", }} />
                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Chat-Box(Both private and group chat)</div>
                </div>
                <div style={{ display: "flex", marginTop: "13px" }}>
                  <img src={star1} style={{ marginTop: "-17px", }} />
                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>File sharing and  recording</div>
                </div>
                <div style={{ display: "flex", marginTop: "13px" }}>
                  <img src={star1} />
                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Hand-Raise</div>
                </div>
                <div style={{ display: "flex", marginTop: "13px" }}>
                  <img src={star1} />
                  <div className='subtitle-500 color-grey' style={{ marginLeft: "10px" }}>Touch-to-talk</div>
                </div>

              </div>
            </div>
          </Tabs.TabPane>
        </Tabs>

      </Hidden>

      <div className='title-large-800 color-black' style={{ fontSize: "1.9rem", textAlign: "center", marginTop: "30px" }}>Premium Features</div>
      <Visible md lg xl xxl>
        <Row >
          <Col span={8}>
            <Row5 image={ic_hand_raise1} title={'Hand raise'} subtitle={hand_raise} />

          </Col>
          <Col span={8} offset={8} >
            <Row5 image={ic_touch_to_talk1} title={'Touch-to-talk'} subtitle={touch_to_talk} />
            {/* <div style={{ marginTop: "20px", borderRadius: "12px", width: "110px", height: "20px", backgroundColor: "#594099" }}><div style={{ color: "#FFFFFF", textAlign: "center", fontSize: "11px", fontWeight: 600 }}> Coming soon</div></div> */}

          </Col>
          <Col span={8}>
            <Row5 image={Group_5804} title={'Live recording'} subtitle={live_recording} />
          </Col>
          <Col span={8} offset={8}>
            <Row5 image={ic_evaluation2} title={'Digital white board'} subtitle={digital_library_white} />
          </Col>
        </Row>
      </Visible>
      <Hidden md lg xl xxl>
        <Row span={24}>
          <Col >
            <Row15 image={ic_hand_raise1} title={'Hand raise'} subtitle={hand_raise} />

          </Col>
          <Col  >
            <Row15 image={ic_touch_to_talk1} title={'Touch-to-talk'} subtitle={touch_to_talk} />
          </Col>
        </Row>
        <Row span={24}>
          <Col >
            <Row15 image={Group_5804} title={'Live recording'} subtitle={live_recording} />
          </Col>
          <Col style={{ marginTop: "10px" }}>
            <Row15 image={ic_evaluation2} title={'Digital white board'} subtitle={digital_library_white} />
          </Col>
        </Row>
      </Hidden>



      {/* <Row3 image={liveRecording} title={'Live recording'} subtitle={live_recording} index={1} />
     */}
      <Row3 image={liveRecording} title={'Digital Library'} subtitle={digital_library} index={1} />

      <Visible md lg xl xxl>
        <Row span={24}>

          <Col span={8}>
            <Row2 image={ic_media} title={'Three types of media'} />
            < div style={{ display: "flex" }}>
              <img src={star1} style={{ marginTop: "-5px", }} />

              <div style={{ marginTop: "29px", marginLeft: "10px", fontSize: "13px" }} className='color-black m-b-10 m-t-10 font-600'>VIDEOS: </div>
              <div style={{ marginTop: "25px", marginLeft: "20px" }}>
                {/* <div className='subtitle-500 color-grey'> */}
                <ul className='subtitle-500 color-grey'>
                  <li>Unlimited YouTube links (No storage needed),</li>
                  <li> locally Uploaded Videos (mp4, mkv, flv, avi, mov)</li>
                </ul></div>

              {/* </div> */}
            </div>
            <Row1 image={pdf1} title={'LECTURE NOTES'} />
            <Row1 image={pdf2} title={'QUESTION BANK'} />


            {/* <div image={media_player1}></div> */}
          </Col>
          <Col span={8}>
            <Row2 image={ic_folder} title={'Multi-Layered Folder system'} subtitle={folder_system} location={'multi'} />
          </Col>
        </Row>

      </Visible>
      <Hidden md lg xl xxl>
        <Row>

          <Col>
            <Row14 image={ic_media} subtitle={three_types} />
          </Col>

        </Row>
        <Row>
          <Col>
            <Row16 subtitle={folder_system} />
          </Col>
        </Row>
      </Hidden>



      <Row4 image={test} title={'Test'} subtitle={test_aulas} />



      {/* <Row>
      <Col xs={12} sm={6} md={4}>
        <Row2 image={ic_create} title={'Create'} subtitle={create_aulas} /> */}
      {/* <div style={{ borderRadius: "12px", width: "110px", height: "20px", backgroundColor: "#594099" }}><div style={{ color: "#FFFFFF", textAlign: "center", fontSize: "11px", fontWeight: 600 }}> Coming soon</div></div> */}
      {/* </Col>

      <Col xs={12} sm={6} md={4}>
        <Row2 image={ic_import} title={'Import'} subtitle={import_detail} />
      </Col>
      <Col xs={12} sm={6} md={4}>
        <Row2 image={ic_evaluation1} title={'Evaluate'} subtitle={evalution} />
      </Col>

    </Row> */}

      <Row9 image={Rectangle_362} title={'Fee Management'} subtitle={fee_management} />
      <div style={{ textAlign: "center" }}>
        <img src={Group12f1} />
      </div>
      <center className='title-large-800 color-black' style={{ fontSize: "1.9rem" }}>
        Planning and Organising
      </center>

      <Visible md lg xl xxl>
        <Row span={24}>

          <Col>
            <Row10 image={ic_user_management1} title={'User Management'} subtitle={userManagementText} />
          </Col>
          <Col >
            <Row10 image={ic_classrooms1_2} title={'Classrooms'} subtitle={classroomsText} />
          </Col>
          <Col >
            <Row10 image={ic_courses1_2} title={'Courses'} subtitle={coursesText} />
            {/* <div style={{ borderRadius: "12px", width: "110px", height: "20px", backgroundColor: "#594099" }}><div style={{ color: "#FFFFFF", textAlign: "center", fontSize: "11px", fontWeight: 600 }}> Coming soon</div></div> */}
          </Col>
          <Col >
            <Row10 image={schedule1_1} title={'Schedule'} subtitle={scheduleText} />
            {/* <div style={{ borderRadius: "12px", width: "110px", height: "20px", backgroundColor: "#594099" }}><div style={{ color: "#FFFFFF", textAlign: "center", fontSize: "11px", fontWeight: 600 }}> Coming soon</div></div> */}
          </Col>
        </Row>

      </Visible>
      <Hidden md lg xl xxl>
        <Row>

          <Col>
            <Row17 image={ic_user_management1} title={'User Management'} subtitle={userManagementText} />
          </Col>
          <Col >
            <Row17 image={ic_classrooms1_2} title={'Classrooms'} subtitle={classroomsText} />
          </Col>
        </Row>
        <Row>
          <Col >
            <Row17 image={ic_courses1_2} title={'Courses'} subtitle={coursesText} />
            {/* <div style={{ borderRadius: "12px", width: "110px", height: "20px", backgroundColor: "#594099" }}><div style={{ color: "#FFFFFF", textAlign: "center", fontSize: "11px", fontWeight: 600 }}> Coming soon</div></div> */}
          </Col>
          <Col >
            <Row17 image={schedule1_1} title={'Schedule'} subtitle={live_dash} />
            {/* <div style={{ borderRadius: "12px", width: "110px", height: "20px", backgroundColor: "#594099" }}><div style={{ color: "#FFFFFF", textAlign: "center", fontSize: "11px", fontWeight: 600 }}> Coming soon</div></div> */}
          </Col>
        </Row>
      </Hidden>



      <Row11 image={attendance_1} title={'Attendance'} subtitle={attendace_Detail} />
      <Visible md lg xl xxl>
        <Row>
          <Col span={8}>
            <Row12 image={geo_fence1} title={'Geo fencing'} subtitle={geo_fence} />
          </Col>
          <Col span={8} offset={8}>
            <Row12 image={leaves_details1} title={'Leave Management'} subtitle={leaves_details} />
          </Col>
          <Col span={8}>
            <Row12 image={holiday_details1} title={'Holiday Management'} subtitle={holiday_details} />
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={8}>
            <Row12 image={students_offline_attendance1} title={'Online Attendance'} subtitle={students_offline_attendance} />
          </Col>
          <Col span={8} offset={8}>
            <Row12 image={students_offline_attendance1} title={'Offline Attendance'} subtitle={students_offline_attendance12} />
          </Col>
          <Col span={8}>
            {/* <Row12 image={holiday_details1} title={'Holiday Management'} subtitle={holiday_details} /> */}
          </Col>
        </Row>

      </Visible>
      <Hidden md lg xl xxl>
        <CarouselFeatures />



      </Hidden>


      {/* <Row4 image={planning_org} title={'Planning and organising'} subtitle={planning_and_org} /> */}
      <Row13 image={communicationsupport} title={'Communication and Support'} subtitle={communication_support} />
    </Container>
  )
};

export default observer(Features);
