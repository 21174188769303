import React from "react";

const Container = ({ children }) => (
  <div
    style={{
      width: "100%",
      maxWidth: 1200,
      height: "100%",
      margin: '0 auto'
    }}
  >
    {children}
  </div>
);

export default Container;
