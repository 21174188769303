import React from "react";
import { Row, Col, Hidden, Visible } from "react-grid-system";
import { observer } from "mobx-react";
import Carousel from 'react-multi-carousel';
import { Container } from "components";
import aboutusbanner from "assets/about-us/aboutusbanner.png";
import aboutUsCurl from "assets/about-us/aboutUsCurl.svg";
import aboutUsCurl2 from "assets/about-us/aboutUsCurl2.svg";
import greenBubble1 from "assets/about-us/greenBubble1.svg";
import greenBubble2 from "assets/about-us/greenBubble2.svg";
import greenBubble3 from "assets/about-us/greenBubble3.svg";
import greenBubble4 from "assets/about-us/greenBubble4.svg";
import greenBubble5 from "assets/about-us/greenBubble5.svg";
import greenBubble6 from "assets/about-us/greenBubble6.svg";
import psychological from "assets/about-us/psychological.svg";
import technological from "assets/about-us/technological.svg";
import ceo from "assets/about-us/ceo.svg";
import quoteLeft from "assets/about-us/quoteLeft.svg";
import quoteRight from "assets/about-us/quoteRight.svg";
import sliderIndicator from "assets/about-us/sliderIndicator.svg";
import sliderIndicatorLight from "assets/about-us/sliderIndicatorLight.svg";
import Group_about_us from "assets/about-us/Group_about_us.svg";
// import Path_52834 from "assets/about-us/Path_52834.svg"
import Ellipse_62 from "assets/about-us/Ellipse_62.svg"
import abcTraingle from "assets/about-us/abcTraingle.svg"
import abc1 from "assets/about-us/abc1.svg"
import abc2 from "assets/about-us/abc2.svg"
import abc3 from "assets/about-us/abc3.svg"
import abc4 from "assets/about-us/abc4.svg"
import abc5 from "assets/about-us/abc5.svg"
import abc6 from "assets/about-us/abc6.svg"
import abc7 from "assets/about-us/abc7.svg"
import Group_16053 from "assets/about-us/Group_16053.svg"
import Vector1_1 from "assets/about-us/Vector1_1.svg"
import Vector1_2 from "assets/about-us/Vector1_2.svg"
import Ellipse_22 from "assets/about-us/Ellipse_22.svg"


import Store from './store';
import './styles.css';


const paragraph1 = 'Technology as presently utilized in schools and colleges is more problem than solution. We are educationalists on a mission to solve problems in offline education with the help of technology. In our industry, those that succeed at this task are diligent about understanding - or seeking to understand - the domain as well as challenges for which they intend to devise solutions. Consequently, we’ve been hard at work to develop online learning solutions that are inclusive and adaptable.';
const paragraph3 = 'Having been instructors and learners at one time or another, we understand that the road to comprehensive and sustainable online learning is much more than just being able to conduct live classes or upload printed or pre-recorded content. We have diligently combed and digested relevant learning scenarios and interviewed hundreds of teachers and students to understand their actual remote learning requirements. We have also taken cues from the first generation of e-learning applications and combined them with newer ideas to build, what we believe, is the perfect alternative for all kinds of physical learning. In the final analysis, we hold that:';
const box1content = 'Multi-channel learning is highly effective.';
const box2content = 'There must be a framework for action consistent with the needs and strategies of an institution.';
const box3content = 'Using multimedia technologies for teaching aids retention and enhances learning.';
const box4content = 'Developing quality learning objects consistently requires a process that must integrate technology, people and practices.';
const box5content = 'E-Learning is both cost effective and efficient.';
const box6content = 'The Internet is a cost-effective delivery platform for eLearning.';
const ceoText = 'The last few decades have, globally, witnessed a digital revolution that has influenced all kinds of human transactions and exchanges, with education being the only exception. However, 2020 and covid-19 have completely turned the tables. Suddenly, we’re heading towards a fundamental reshaping of the entirety of education, for our times, and for times to come. Learners and teachers worldwide are now (about to be) in contact with one another through educational opportunities which are of a different dimension altogether.';
const discover1 = 'Digital learning infrastructures are evolving at a rapid pace in the post Covid-19 pandemic world. Greater demand for online solutions has resulted in greater indulgence leading to an increased understanding of the gaps, complexities and variables concerning complete digital onboarding of educational entities.'
const discover2 = 'Having been instructors and learners at one time or another, we understand that the road to comprehensive online learning and online management of an educational system is much more than just conducting online classes or uploading videos.'
const All_in_one = 'All-in-one solutions are highly necessary for ease of adaptation.'
const digital_frame = 'Digital framework for action must integrate technology, people, practices and behaviours of people.'
const digital_frame1 = 'Digital framework for action must be in alignment with the needs and strategies of an educational system.'
const digital_frame2 = 'Needs and strategies of all educational systems are unique.'

const digital_frame3 = 'One-size-fits all approach is a recipe for disaster.'
const digital_frame4 = 'Using multimedia technologies aids retention and enhances learning.'

const digital_frame5 = 'E-learning, hybrid learning are both cost effective and efficient.'



const AboutBox = ({ img, content }) => (
  <div className='m-t-50 c-cb about-box-wrap'>
    <div className='position-relative r-c-c' style={{ width: 85 }}>
      <img src={img} alt="" style={{ width: '100%', }} />
      {/*<div className='title-800 color-green position-absolute'>{number}</div>*/}
    </div>
    <div className='title-500 color-black'>{content}</div>
  </div>
)

const Row1 = ({ image, image2, title, image3, subtitle, newsubtitle, }) => (
  <Row>
    <Col xs={12} sm={6} md={7} >
      <img src={image} alt="" style={{ textAlign: "center", width: '80%' }} />
    </Col>
    <Col xs={12} sm={6} md={5} className='c-jc'>
      <img src={image2} alt="" style={{ marginLeft: "280px", width: "5%", }} />

      <div className='title-800 color-black m-b-20' style={{ fontSize: "2.06rem" }}>{title}</div>
      <div style={{ textAlign: "justify", }} className='subtitle-500 color-grey'>{subtitle}</div>
      <div style={{ textAlign: "justify", marginTop: "12px" }} className='subtitle-500 color-grey'>{newsubtitle}</div>
      <img src={image3} alt="" style={{ marginLeft: "200px", marginTop: "40px", width: "5%" }} />
    </Col>

  </Row>
)
const Row3 = ({ image, image2, title, image3, subtitle, newsubtitle, }) => (
  <Row>
    <Col xs={12} sm={6} md={7} >
      <img src={image} alt="" style={{ textAlign: "center", width: '80%' }} />
    </Col>
    <Col xs={12} sm={6} md={5} className='c-jc'>
      <img src={image2} alt="" style={{ marginLeft: "280px", width: "5%", }} />

      <div className='title-800 color-black m-b-20' style={{ fontSize: "2.06rem" }}>{title}</div>
      <div style={{ textAlign: "justify", }} className='subtitle-500 color-grey'>{subtitle}</div>
      <div style={{ textAlign: "justify", marginTop: "12px" }} className='subtitle-500 color-grey'>{newsubtitle}</div>
      <img src={image3} alt="" style={{ marginLeft: "60px", marginTop: "20px", width: "8%" }} />
    </Col>

  </Row>
)
const Row2 = ({ image, title, subtitle }) => (
  <div className='m-t-100'>
    <div style={{ wdith: "26%" }}>
      <img src={image} alt="" style={{ width: "15%", }} />
    </div>
    <div className='subtitle-500 color-grey' style={{ wdith: "26%", marginTop: "50px", fontWeight: "bold" }}>{subtitle}</div>
  </div>
)
const Row4 = ({ image, title, subtitle }) => (
  <div className='m-t-100'>
    <div style={{ wdith: "26%" }}>
      <img src={image} alt="" style={{ width: "15%", }} />
    </div>
    <div className='subtitle-500 color-grey' style={{ fontWeight: "bold", marginTop: "12px" }}>{subtitle}</div>
  </div>
)


const About = () => (
  <div>
    <Container>
      <Visible md lg xl xxl>
        <Row1 image={Group_about_us} image2={abcTraingle} image3={Ellipse_62} title={'About Us'} subtitle={discover1} newsubtitle={discover2} />
        <div className='subtitle-500 color-grey' style={{ textAlign: "justify", fontSize: "1.1rem", textAlign: "center", marginTop: "90px" }}>
          We have diligently combed and digested relevant learning scenarios and interviewed hundreds of educators to understand their remote learning requirements. In the final analysis, we hold that:
        </div>
      </Visible>
      <Hidden md lg xl xxl>
        <Row3 image={Group_about_us} image2={abcTraingle} image3={Ellipse_62} title={'About Us'} subtitle={discover1} newsubtitle={discover2} />
        <div className='subtitle-500 color-grey' style={{ textAlign: "justify", fontSize: "1.1rem", textAlign: "center", marginTop: "10px" }}>
          We have diligently combed and digested relevant learning scenarios and interviewed hundreds of educators to understand their remote learning requirements. In the final analysis, we hold that:
        </div>
      </Hidden>



      <Visible md lg xl xxl>
        <Row span={24}>
          <Col >
            <Row2 image={abc1} subtitle={All_in_one} />
          </Col>
          <Col style={{ marginTop: "4px" }}>
            <Row2 image={abc2} subtitle={digital_frame} />
          </Col>
          <Col style={{ marginTop: "-4px" }} >
            <Row2 image={abc3} subtitle={digital_frame1} />

          </Col>
          <Col >
            <Row2 image={abc4} subtitle={digital_frame2} />
          </Col>
        </Row>
        <Row span={24} style={{ marginTop: "-30px" }}>
          <Col span={5}>
            <Row2 image={abc5} subtitle={digital_frame3} />
          </Col>
          <Col span={5} style={{ marginTop: "3px" }}>
            <Row2 image={abc6} subtitle={digital_frame4} />
          </Col>
          <Col span={5} style={{ marginTop: "-4px" }}>
            <Row2 image={abc7} subtitle={digital_frame5} />
          </Col>
          <Col span={5}>
          </Col>
        </Row>

      </Visible>
      <Hidden md lg xl xxl>
        <Row >
          <Col  >
            <Row4 image={abc1} subtitle={All_in_one} />
          </Col>
          <Col >
            <Row4 image={abc2} subtitle={digital_frame} />
          </Col>
        </Row>
        <Row style={{ marginTop: "-50px" }}>
          <Col  >
            <Row4 image={abc3} subtitle={digital_frame1} />
          </Col>
          <Col >
            <Row4 image={abc4} subtitle={digital_frame2} />
          </Col>
        </Row>
        <Row style={{ marginTop: "-50px" }} >
          <Col >
            <Row4 image={abc5} subtitle={digital_frame3} />
          </Col>
          <Col >
            <Row4 image={abc6} subtitle={digital_frame4} />
          </Col>
        </Row>
        <Row style={{ marginTop: "-50px" }}>
          <Col >
            <Row4 image={abc7} subtitle={digital_frame5} />
          </Col>
          <Col >
          </Col>
        </Row>
      </Hidden>







      <div style={{ textAlign: "center", marginTop: "50px" }} >
        <img src={Group_16053} />
      </div>

      <Visible md lg xl xxl>
        <div style={{ textAlign: "center", color: "#594099", fontSize: "14px", fontWeight: "bold", marginTop: "40px" }}>
          FROM THE CEO’S DESK
        </div>

        <div>
          <img src={Vector1_2} style={{ opacity: 0.2, marginBottom: "-100px" }} />
        </div>
        <div className="subtitle-500 color-black ceo-quote" style={{ marginLeft: "60px", textAlign: "center", color: "black", fontWeight: 500, marginTop: "40px", fontSize: "21px" }}>
          The last few years have, globally, witnessed a digital revolution that has influenced the functioning of all kinds of educational systems. While we have come a long way in the journey of adapting to online processes, there is still a lot of work that needs to be done. Learners and educators are beginning to engage with one another through opportunities which were unheard-of until a couple of years ago. Our dream is to take it to an unimaginable dimension altogether in the shortest time possible.
        </div>
        <div style={{ textAlign: "right", marginTop: "-100px" }}>
          <img src={Vector1_1} style={{ opacity: 0.2 }} />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <img src={Ellipse_22} />
          </div>
          <div style={{ marginLeft: "10px", marginTop: "10px" }}>
            <div style={{ color: "#373F41", fontSize: "14px", fontWeight: "bold" }}>
              Brijesh Singh
            </div>
            <div style={{ color: "#737B7D", fontSize: "12px", fontWeight: 400 }}>
              CEO
            </div>
          </div>
        </div>

      </Visible>
      <Hidden md lg xl xxl>
        <div style={{ textAlign: "center", color: "#594099", fontSize: "14px", fontWeight: "bold", marginTop: "40px" }}>
          FROM THE CEO’S DESK
        </div>

        <div>
          <img src={Vector1_2} style={{ opacity: 0.2, marginBottom: "-100px", width: "30%" }} />
        </div>
        <div className="subtitle-500 color-black ceo-quote" style={{ fontWeight: 600, marginTop: "40px", fontSize: "15px" }}>
          The last few years have, globally, witnessed a digital revolution that has influenced the functioning of all kinds of educational systems. While we have come a long way in the journey of adapting to online processes, there is still a lot of work that needs to be done. Learners and educators are beginning to engage with one another through opportunities which were unheard-of until a couple of years ago. Our dream is to take it to an unimaginable dimension altogether in the shortest time possible.
        </div>
        <div style={{ textAlign: "right", marginTop: "-70px" }}>
          <img src={Vector1_1} style={{ opacity: 0.2, width: "30%" }} />
        </div>

        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <div>
            <img src={Ellipse_22} />
          </div>
          <div style={{ marginLeft: "10px", marginTop: "10px" }}>
            <div style={{ color: "#373F41", fontSize: "14px", fontWeight: "bold" }}>
              Brijesh Singh
            </div>
            <div style={{ color: "#737B7D", fontSize: "12px", fontWeight: 400 }}>
              CEO
            </div>
          </div>
        </div>

      </Hidden>


    </Container>

    {/* <Container>
      <Hidden xs sm md>
        <div className='m-t-100'>
          <div className='position-relative m-t-100 ceo-quote-wrap'>
            <div className='title-small-spacing-700 text-center color-pinkred'>FROM THE PRESIDENTS DESK</div>
            <div className="">
              <img src={quoteLeft} alt="" className="ceo-left-quote" />
              <div className="title-700 color-black ceo-quote" style={{ fontWeight: 700 }}>{ceoText}</div>
              <img src={quoteRight} alt="" className="ceo-right-quote" />
            </div>
            <div className='r-ac'>
              <img src={ceo} alt="" className='' />
              <div className='title-400 color-black m-l-10'>Brijesh Singh</div>
            </div>
          </div>
        </div>
      </Hidden>
      <Visible xs>
        <div className='m-t-100'>
          <div className='position-relative ceo-quote-wrap' style={{ padding: '20px' }}>
            <div className='title-small-spacing-700 text-center m-b-10 color-pinkred'>FROM THE PRESIDENTS DESK</div>
            <div className="">
              <img src={quoteLeft} alt="" className="ceo-left-quote" style={{ width: '80px' }} />
              <div className="subtitle-500 color-black ceo-quote" style={{ fontWeight: 700 }}>{ceoText}</div>
              <img src={quoteRight} alt="" className="ceo-right-quote" style={{ width: '80px' }} />
            </div>
            <div className='r-ac'>
              <img src={ceo} alt="" className='' />
              <div className='title-400 color-black m-l-10'>Brijesh Singh</div>
            </div>
          </div>
        </div>
      </Visible>
      <Visible sm md>
        <div className='position-relative border-10 ceo-quote-wrap'>
          <div className='title-small-spacing-700 text-center color-pinkred'>FROM THE PRESIDENTS DESK</div>
          <div className="">
            <img src={quoteLeft} alt="" className="ceo-left-quote" />
            <div className="title-400 color-black ceo-quote" style={{ fontWeight: 700 }}>{ceoText}</div>
            <img src={quoteRight} alt="" className="ceo-right-quote" />
          </div>
          <div className='r-ac'>
            <img src={ceo} alt="" className='' />
            <div className='title-400 color-black m-l-10'>Brijesh Singh</div>
          </div>
        </div>
      </Visible>
    </Container> */}
  </div>
);

export default observer(About);
