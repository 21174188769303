import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Layout } from "antd";
import { Home, About, Support, Features, RequestDemo, FourOFourPage, Success, PrivacyPolicy, TermsConditions, AppleUniversalLink, Redirect, Blog, BlogDetails } from "pages";
import { observer } from "mobx-react";
import {
  CustomHeader,
  Container,
  CustomFooter,
  CustomSideBar,

} from "components";
import CustomJoinUs from "components/customJoinus"
import Sidebar from "react-sidebar";
import Store from "services/store";
import './styles.css';

const { Header, Footer, Content, Sider } = Layout;

class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topBarActive: false,
    }
  }


  render() {

    if (window.location.pathname === '/register') {
      window.location.href = "/register-cfi";
    }

    if (window.location.pathname === '/register-cfi') {
      return <RequestDemo />
    }
    if (window.location.pathname === '/register-success/') {
      return <Success />
    }
    if (window.location.pathname === "/.well-known/apple-app-site-association") {
      return <AppleUniversalLink />
    }
    window.onscroll = function (ev) {
      if (window.scrollY < 65) {
        this.setState({ topBarActive: false })
      } else {
        this.setState({ topBarActive: true })
      }
    }.bind(this);

    return (
      <Router>
        <Layout className="layout-body">
          <Sidebar
            sidebar={<CustomSideBar />}
            open={!Store.isSiderClosed}
            // onSetOpen={this.onSetSidebarOpen}
            styles={{ sidebar: { background: "white" } }}
          >
            <div></div>
          </Sidebar>
          <Layout className="layout-body">
            <Header
              style={{
                display: "flex",
                justifyContent: "center",
                background: this.state.topBarActive ? '#fff' : 'transparent',
                position: 'sticky',
                top: '0px',
                zIndex: 105,
                boxShadow: this.state.topBarActive ? '0px 2px 15px 0px lightgrey' : 'none',
              }}
              className="header-layout"
            >
              <Container>
                <CustomHeader />
              </Container>
            </Header>
            <Content className='body-padding'>
              <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/about-us" component={About}/>
                <Route path="/redirect" component={Redirect}/>
                <Route exact path="/blog" component={Blog}/>
                <Route exact path="/blog/:id" component={BlogDetails}/>
                <Route exact path="/blog-detail" component={BlogDetails}/>
                <Route exact path="/contact-us" component={Support}/>
                <Route exact path="/features" component={Features}/>
                <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
                <Route exact path="/terms-conditions" component={TermsConditions}/>
                {/* <Route exact path="/.well-known/apple-app-site-association" component={AppleUniversalLink} /> */}
                <Route component={FourOFourPage} />
              </Switch>
            </Content>
            {/* <Footer
              style={{ backgroundColor: '#F6F4FE', height: 250, marginBottom: -110 }}
            >
              <CustomJoinUs />

            </Footer> */}
            <Footer
              style={{
                display: "flex",
                justifyContent: "center",
                minHeight: 242,
                padding: '50px 20px',
                backgroundColor: "#F8F8F8",
                // marginTop: 110
              }}
            >
              <Container>
                <CustomFooter />
              </Container>
            </Footer>
          </Layout>
        </Layout>
      </Router>
    )
  }
}

export default observer(Root);
