import React from "react";
import flowerpot1 from "assets/home/flowerpot1.svg"
import s1 from "assets/home/s1.svg"
import s2 from "assets/home/s2.svg"
import s3 from "assets/home/s3.svg"
import s4 from "assets/home/s4.svg"
import s5 from "assets/home/s5.svg"
import s6 from "assets/home/s6.svg"
import s7 from "assets/home/s7.svg"
import s8 from "assets/home/s8.svg"
import s9 from "assets/home/s9.svg"
import schedule1 from "assets/home/schedule1.svg"
import s10 from "assets/home/s10.svg"
import '../pages/about/styles.css';
import Marquee from "react-fast-marquee";



import '../pages/home/styles.css'
import { Hidden, Visible } from "react-grid-system";
import { Col } from "antd";

// const Row4 = ({ image, title, subtitle }) => (
//     <div style={{ marginLeft: "30px" }}>
//         <Col xs={12} sm={6} md={7} className='m-b-20 r-fe-c'>
//             <div className='title-500 color-black m-b-10  font-600' style={{ marginTop: "-200px", marginRight: "-50px" }}>{title}</div>
//         </Col>
//     </div>

// )
const CustomSeamless = () => {

    return (

        <div
            style={{
                // height: "500%",
                width: "100%",
                overflowX: 'hidden',
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                backgroundColor: "#F6F4FE",
                marginTop: "100px",
                // zIndex: 1,
                position: "absolute",
                // marginLeft: '-8%'
            }}
        >

            <div style={{ width: "100%", backgroundColor: "#F6F4FE" }}>
                <Visible sm md lg xl xxl >
                    <div className='title-large-800 color-black' style={{ textAlign: "center", fontSize: "1.9rem", marginTop: 20, marginBottom: 20, }}> A seamless mobile and web interface</div>
                </Visible>
                {/* <Hidden md lg xl xxl>
                </Hidden> */}
                <Visible md lg xl xxl>
                    <Marquee speed={48} gradient={false}>

                        <div className="tech-slideshow" style={{ width: '120%', display: 'flex', position: "relative" }}>
                            <div style={{ marginRight: 20 }}>
                                <img src={s1} />
                            </div>
                            <div style={{ marginRight: 20 }}>
                                <img src={s2} />
                            </div>
                            <div style={{ marginRight: 20 }}>
                                <img src={s3} />
                            </div>
                            <div style={{ marginRight: 20 }}>
                                <img src={s4} />
                            </div>
                            <div style={{ marginRight: 20 }}>
                                <img src={s5} />
                            </div>
                            <div style={{ marginRight: 20 }}>
                                <img src={s6} />
                            </div>
                            <div style={{ marginRight: 20 }}>
                                <img src={s7} />
                            </div>
                            <div style={{ marginRight: 20 }}>
                                <img src={s8} />
                            </div>
                            <div style={{ marginRight: 20 }}>
                                <img src={s9} />
                            </div>
                            <div style={{ marginRight: 20 }}>
                                <img src={s10} />
                            </div>
                        </div>
                    </Marquee>

                </Visible>










            </div>

        </div>
    )
}


export default CustomSeamless;
