import { observable, action, makeObservable, computed } from "mobx";
import { message } from 'antd';
import Api from "../../services/Api";
import {CountryConverter} from "./countryConverter";
// import AppState from "../../Store";

class Store {
  showRequestModalBool = false;
  requestFields = {
    name: '',
    email: '',
    phone: '',
    role: '',
    strength: '',
    orgName: '',
    country: '',
    knowMore: '',
    requestDemo: true,
  };
  requestFieldsLoader = false;



  constructor() {
      makeObservable(this, {
          clearRequestDemo: observable,
          showRequestModalBool: observable,
          requestFields: observable,
          requestFieldsLoader: observable,
          requestDemo: action,
          getRole: action,
          getStrength: action,
      })
  }

  showRequestModal(bool){
    this.showRequestModalBool = bool;
  }

  clearRequestDemo(){
    this.requestFields.name = '';
    this.requestFields.email = '';
    this.requestFields.phone = '';
    this.requestFields.role = '';
    this.requestFields.strength = '';
    this.requestFields.orgName = '';
    this.requestFields.country = '';
    this.requestFields.knowMore = '';
    this.requestFields.requestDemo = true;
  }

  getRole(text){
    this.requestFields.role = text;
  }

  getStrength(text){
    this.requestFields.strength = text;
  }

  requestDemo(source){
    const params = {
      name: this.requestFields.name,
      email: this.requestFields.email,
      phone: this.requestFields.phone,
      role: this.requestFields.role,
      strength: this.requestFields.strength,
      orgName: this.requestFields.orgName,
      message: this.requestFields.knowMore,
      demoRequested: this.requestFields.requestDemo,
      country: CountryConverter[this.requestFields.country],
      source
    };

    if(this.requestFields.name === '' || this.requestFields.email === '' || this.requestFields.orgName === '' || this.requestFields.phone === '' || this.requestFields.role === '' || this.requestFields.strength === ''){
      message.error('Please fill all the details');
      return;
    }
    // if(this.requestFields.phone.length < 10 || this.requestFields.phone.length > 10){
    //   message.error('Please Enter 10 digit phone number')
    //   return;
    // }
    const url = `/register-success/?email=${this.requestFields.email}`;
    this.requestFieldsLoader = true;
    Api.post('/api/b2b/request-demo')
    .params(params)
    .send((response, error) => {
      this.requestFieldsLoader = false;
      // if(response !== undefined){
        // message.success('Request sent successfully!');
        window.location.href = url;
        this.clearRequestDemo();
      // }
    });
  }
}

export default new Store();
