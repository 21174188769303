import { Carousel } from 'antd';
import React from 'react';
import students_offline_attendance1 from "assets/features/students_offline_attendance1.svg"
import leaves_details1 from "assets/features/leaves_details1.svg"
import holiday_details1 from "assets/features/holiday_details1.svg"
import geo_fence1 from "assets/features/geo_fence1.svg"

const contentStyle = {
    height: '160px',
    BackgroundColor: 'red',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

const CarouselFeatures = () => (
    <div className='m-t-50'>
        <Carousel autoplay={true}>
            <div style={{ contentStyle }}>
                <img src={geo_fence1} style={{width: '15%'}}/>
                <div className='title-500 color-black m-b-10 m-t-10 font-600'>Geo Fencing</div>

                <div className='subtitle-500 color-grey'>Locate your institute on Google maps and set a permissible radius within which associates can punch-in and punch-out from. Multiple locations are permitted.</div>
            </div>
            <div style={{ contentStyle }}>
                <img src={leaves_details1} style={{width: '15%'}}/>
                <div className='title-500 color-black m-b-10 m-t-10 font-600'>Leave Management</div>
                <div className='subtitle-500 color-grey'>Define the number of permissible casual, sick & other leaves for your institute in advance. Provides a seamless leave application and approval system.</div>
            </div>
            <div style={{ contentStyle }}>
                <img src={holiday_details1} style={{width: '15%'}}/>
                <div className='title-500 color-black m-b-10 m-t-10 font-600'>Holiday Management</div>

                <div className='subtitle-500 color-grey'>Locate your institute on Google maps and set a permissible radius within which associates can punch-in and punch-out from. Multiple locations are permitted.</div>
            </div>
            <div style={{ contentStyle }}>
                <img src={students_offline_attendance1} style={{width: '15%'}}/>
                <div className='title-500 color-black m-b-10 m-t-10 font-600'>Online Attendance</div>

                <div className='subtitle-500 color-grey'>Locate your institute on Google maps and set a permissible radius within which associates can punch-in and punch-out from. Multiple locations are permitted.</div>
            </div>
            <div style={{ contentStyle }}>
                <img src={students_offline_attendance1} style={{width: '15%'}}/>
                <div className='title-500 color-black m-b-10 m-t-10 font-600'>Offline Attendance</div>
                <div className='subtitle-500 color-grey'>Locate your institute on Google maps and set a permissible radius within which associates can punch-in and punch-out from. Multiple locations are permitted.</div>
            </div>
        </Carousel >


    </div>
);

export default CarouselFeatures;