import React from "react";
import { Link } from "react-router-dom";
import { Button } from 'antd';
import CustomHeader from "./CustomHeader";
import { LeftOutlined } from "@ant-design/icons";
import Store from "services/store";
import HomeStore from "pages/home/store";


class CustomSideBar extends React.Component{

  pageGetTopNavIndex = (index) => {
    Store.getTopNavIndex(index);
    Store.toggleSider();
    HomeStore.clearRequestDemo();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth'});
  }

  render(){
    return(
      <div className="p-h-50" style={{height:'100%',/*  zIndex: 102 */}}>
        {/*<CustomHeader icon="close" />*/}
        <div
          style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent:'center', height:'100%'}}
        >

          <div
            className="color-black title-700 position-absolute r-c-c"
            onClick={() => Store.toggleSider()}
            style={{background:'#74787e', top:'66px', left: 210 ,borderRadius:50, width:'78px', height:'80px', color:'#fff', opacity: 0.5}}
          >
            <LeftOutlined/>
          </div>
          <Link
            onClick={() => this.pageGetTopNavIndex(1)}
            className="title-500 color-black"
            to="/"
            style={{ textDecoration: "none", color: Store.activeTopNav === 1 ? '#0779E4' : '#393e46', fontWeight: Store.activeTopNav === 1 ? '600' : '400' }}
          >
            HOME
          </Link>
          <br />
          <br />
          <Link
            onClick={() => this.pageGetTopNavIndex(2)}
            to="/features"
            className="title-500 color-black"
            style={{ textDecoration: "none", color: Store.activeTopNav === 2 ? '#0779E4' : '#393e46', fontWeight: Store.activeTopNav === 2 ? '600' : '400' }}
          >
            FEATURES
          </Link>
          <br />
          <br />
          <Link
            onClick={() => this.pageGetTopNavIndex(3)}
            to="/blog"
            className="title-500 color-black"
            style={{ textDecoration: "none", color: Store.activeTopNav === 3 ? '#0779E4' : '#393e46', fontWeight: Store.activeTopNav === 3 ? '600' : '400' }}
          >
            BLOG
          </Link>
          <br />
          <br />
          <Link
            onClick={() => this.pageGetTopNavIndex(4)}
            to="/about-us"
            className="title-500 color-black"
            style={{ textDecoration: "none", color: Store.activeTopNav === 4 ? '#0779E4' : '#393e46', fontWeight: Store.activeTopNav === 4 ? '600' : '400' }}
          >
            ABOUT US
          </Link>
          <br />
          <br />
          <Link
            onClick={() => this.pageGetTopNavIndex(5)}
            to="/contact-us"
            className="title-500 color-black"
            style={{ textDecoration: "none", color: Store.activeTopNav === 5 ? '#0779E4' : '#393e46', fontWeight: Store.activeTopNav === 5 ? '600' : '400' }}
          >
            SUPPORT
          </Link>
          <br />
          <br />
          <Button onClick={() => {
            Store.toggleSider();
            HomeStore.showRequestModal(true);
            }} size={'large'} className='font-500 home-modal-submit-btn' style={{padding:'0px 15px'}}>
            <span className='font-600'>REQUEST DEMO</span>
          </Button>
        </div>
      </div>
    )
  }
}

export default CustomSideBar;
