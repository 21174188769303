import React from "react";
import { Row, Col, Hidden, Visible } from "react-grid-system";
import { Link } from "react-router-dom";
import Store from "services/store";
import facebook from "assets/facebook.svg";
import instagram from "assets/instagram.svg";
import linkedin from "assets/linkedin.svg";
import twitter from "assets/twitter.svg";
import brigoshaLogo from "assets/logo_brigosha.svg";
import googlePlay from "assets/home/googlePlay.svg";
import appStore from "assets/home/appStore.svg";


class CustomFooter extends React.Component {

  pageGetTopNavIndex = (index) => {
    Store.getTopNavIndex(index);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  render() {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <Row>
          <Visible md lg xl xxl >
            <Col xs={12} sm={4} md={3} lg={3}>
              <Link onClick={() => this.pageGetTopNavIndex(1)} to="/" className="color-black" style={{ textDecoration: "none" }} >
                <div className='m-b-10'>HOME</div>
              </Link>
              <Link onClick={() => this.pageGetTopNavIndex(2)} to="/features" className="color-black" style={{ textDecoration: "none" }} >
                <div className='m-b-10'>FEATURES</div>
              </Link>
              <Link onClick={() => this.pageGetTopNavIndex(3)} to="/blog" className="color-black" style={{ textDecoration: "none" }} >
                <div className='m-b-10'>BLOG</div>
              </Link>
              <Link onClick={() => this.pageGetTopNavIndex(4)} to="/about-us" className="color-black" style={{ textDecoration: "none" }} >
                <div className='m-b-10'>ABOUT US</div>
              </Link>
              <Link onClick={() => this.pageGetTopNavIndex(5)} to="/contact-us" className="color-black" style={{ textDecoration: "none" }} >
                <div className='m-b-10'>SUPPORT</div>
              </Link>
              <Link onClick={() => this.pageGetTopNavIndex(6)} to="/privacy-policy" className="color-black" style={{ textDecoration: "none" }} >
                <div className='m-b-10'>PRIVACY POLICY</div>
              </Link>
              <Link onClick={() => this.pageGetTopNavIndex(7)} to="/terms-conditions" className="color-black" style={{ textDecoration: "none" }} >
                TERMS & CONDITIONS
              </Link>
            </Col>
            <Col xs={12} sm={4} md={5} lg={5} style={{}}>
              <div className='m-b-10 color-grey font-600' style={{ letterSpacing: '0.15em' }}>DOWNLOAD OUR APP</div>
              <div className='r-ac'>
                <a href="https://play.google.com/store/apps/details?id=com.classroom.aulas" target="_blank" rel="noopener noreferrer">
                  <img className='m-r-20' src={googlePlay} alt="GooglePlay" style={{ width: '125px' }} />
                </a>
                <a href="https://apps.apple.com/in/app/aulas/id1564724183" target="_blank" rel="noopener noreferrer">
                  <img className='m-r-20' src={appStore} alt="AppleStore" style={{ width: '125px' }} />
                </a>
              </div>
            </Col>
            <Col xs={12} sm={4} md={4} lg={4} style={{}}>
              <div className='m-b-10 color-grey font-600' style={{ letterSpacing: '0.15em' }}>FOLLOW US</div>
              <div>
                <a
                  href="http://www.facebook.com/theaulasapp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className='m-r-20' style={{ color: '#594099' }} src={facebook} alt="Facebook" />
                </a>
                <a
                  href="https://www.instagram.com/theaulasapp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className='m-r-20' src={instagram} alt="Instagram" />
                </a>
                <a
                  href="https://www.linkedin.com/company/theaulasapp/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className='m-r-20' src={linkedin} alt="Linked in" />
                </a>
                <a
                  href="https://twitter.com/theaulasapp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitter} alt="Twitter" />
                </a>
              </div>
            </Col>
          </Visible>
          <Hidden md lg xl xxl >
            <Col xs={12} sm={12} md={4} lg={4}>
              <div className='r-sb-c m-b-20'>
                <Link onClick={() => this.pageGetTopNavIndex(1)} to="/" className="color-black" style={{ textDecoration: "none" }} >
                  HOME
                </Link>
                <Link onClick={() => this.pageGetTopNavIndex(2)} to="/features" className="color-black" style={{ textDecoration: "none" }} >
                  FEATURES
                </Link>
                <Link onClick={() => this.pageGetTopNavIndex(3)} to="/blog" className="color-black" style={{ textDecoration: "none" }} >
                  BLOG
                </Link>
                <Link onClick={() => this.pageGetTopNavIndex(4)} to="/about-us" className="color-black" style={{ textDecoration: "none" }} >
                  ABOUT US
                </Link>
              </div>
              <div className='r-sb-c'>

                <Link onClick={() => this.pageGetTopNavIndex(5)} to="/contact-us" className="color-black" style={{ textDecoration: "none" }} >
                  SUPPORT
                </Link>
                <Link onClick={() => this.pageGetTopNavIndex(6)} to="/privacy-policy" className="color-black" style={{ textDecoration: "none" }} >
                  PRIVACY POLICY
                </Link>
                <Link onClick={() => this.pageGetTopNavIndex(7)} to="/terms-conditions" className="color-black" style={{ textDecoration: "none" }} >
                  TERMS & CONDITIONS
                </Link>
              </div>
            </Col>
            <div className='horizontal-line m-t-40 m-b-40 m-l-10 m-r-10' style={{ background: '#D7D8DA', width: '100%' }}></div>
            <Col xs={12} sm={12} md={4} lg={4} style={{}}>
              <div className='m-b-20 color-grey font-600 text-center' style={{ letterSpacing: '0.15em' }}>DOWNLOAD OUR APP</div>
              <div className='r-c-c'>
                <a href="https://play.google.com/store/apps/details?id=com.classroom.aulas" target="_blank" rel="noopener noreferrer">
                  <img className='m-r-20' src={googlePlay} alt="GooglePlay" style={{ width: '125px' }} />
                </a>
                <a href="https://apps.apple.com/in/app/aulas/id1564724183" target="_blank" rel="noopener noreferrer">
                  <img className='m-r-20' src={appStore} alt="AppleStore" style={{ width: '125px' }} />
                </a>
              </div>
            </Col>
            <div className='horizontal-line m-t-40 m-b-40 m-l-10 m-r-10' style={{ background: '#D7D8DA', width: '100%' }}></div>
            <Col xs={12} sm={12} md={4} lg={4} style={{}}>
              <div className='m-b-20 color-grey font-600 text-center' style={{ letterSpacing: '0.15em' }}>FOLLOW US</div>
              <div className='text-center'>
                <a
                  href="http://www.facebook.com/theaulasapp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className='m-r-20' src={facebook} alt="Facebook" />
                </a>
                <a
                  href="https://www.instagram.com/theaulasapp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className='m-r-20' src={instagram} alt="Instagram" />
                </a>
                <a
                  href="https://www.linkedin.com/company/theaulasapp/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className='m-r-20' src={linkedin} alt="Linked in" />
                </a>
                <a
                  href="https://twitter.com/theaulasapp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitter} alt="Twitter" />
                </a>
              </div>
            </Col>
            <div className='horizontal-line m-t-40 m-b-40 m-l-10 m-r-10' style={{ background: '#D7D8DA', width: '100%' }}></div>
          </Hidden>
        </Row>
        <Row align="end">
          <Visible md lg xl xxl>
            <Col style={{ display: "flex", justifyContent: "center", marginTop: '50px' }} className='text-center'>
              <span>Copyright @ Vectors Education LLP. All rights reserved.</span>
            </Col>
          </Visible>
          <Hidden md lg xl xxl>
            <Col style={{ display: "flex", justifyContent: "center" }} className='text-center'>
              <span>Copyright @ Vectors Education LLP. All rights reserved.</span>
            </Col>
          </Hidden>
        </Row>
      </div>
    )
  }
}

export default CustomFooter;
