import React from "react";
import { Row, Col, Hidden, Visible } from "react-grid-system";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { Modal, Button, Input, Checkbox } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import Store from "./store";
import "./styles.css";

const { TextArea } = Input;

const RequestModal = () => (
  <Modal
    title="Basic Modal"
    visible={Store.showRequestModalBool}
    className="home-request-modal"
    closable={false}
    centered={true}
  >
    <div className="text-right">
      <CloseOutlined onClick={() => Store.showRequestModal(false)} />
    </div>
    <div className='title-500 font-600 text-center m-b-10 color-black'>Request Demo</div>
    <div className='subtitle-400 text-center color-grey'>Please provide us with the following details.</div>
    <div className='color-grey-mid m-b-10 m-t-20'>I am a... <span style={{color:'red'}}>*</span></div>
    <div className='display-flex flex-wrap m-b-20'>
    <Button
        style={{
          background:
            Store.requestFields.role === "Principal" ? "#28DF99" : "#fff",
          color: Store.requestFields.role === "Principal" ? "#fff" : "#393e46"
        }}
        onClick={() => Store.getRole("Principal")}
        className="home-modal-btn m-r-10"
      >
        Principal
      </Button>
      <Button
        style={{
          background:
            Store.requestFields.role === "Teacher" ? "#28DF99" : "#fff",
          color: Store.requestFields.role === "Teacher" ? "#fff" : "#393e46"
        }}
        onClick={() => Store.getRole("Teacher")}
        className="home-modal-btn m-r-10 m-b-10"
      >
        Teacher
      </Button>
      <Button
        style={{
          background:
            Store.requestFields.role === "Administrator" ? "#28DF99" : "#fff",
          color:
            Store.requestFields.role === "Administrator" ? "#fff" : "#393e46"
        }}
        onClick={() => Store.getRole("Administrator")}
        className="home-modal-btn m-r-10 m-b-10"
      >
        Administrator
      </Button>
      <Button
        style={{
          background:
            Store.requestFields.role === "Manager" ? "#28DF99" : "#fff",
          color: Store.requestFields.role === "Manager" ? "#fff" : "#393e46"
        }}
        onClick={() => Store.getRole("Manager")}
        className="home-modal-btn m-r-10 m-b-10"
      >
        Manager
      </Button>
      <Button
        style={{
          background:
            Store.requestFields.role === "Marketing" ? "#28DF99" : "#fff",
          color: Store.requestFields.role === "Marketing" ? "#fff" : "#393e46"
        }}
        onClick={() => Store.getRole("Marketing")}
        className="home-modal-btn m-r-10 m-b-10"
      >
        Marketing
      </Button>
      <Button
        style={{
          background:
            Store.requestFields.role === "Director" ? "#28DF99" : "#fff",
          color: Store.requestFields.role === "Director" ? "#fff" : "#393e46"
        }}
        onClick={() => Store.getRole("Director")}
        className="home-modal-btn m-r-10 m-b-10"
      >
        Director
      </Button>
      <Button
        style={{
          background:
            Store.requestFields.role === " Centre Head" ? "#28DF99" : "#fff",
          color: Store.requestFields.role === " Centre Head" ? "#fff" : "#393e46"
        }}
        onClick={() => Store.getRole(" Centre Head")}
        className="home-modal-btn m-r-10 m-b-10"
      >
         Centre Head
      </Button>
      <Button
        style={{
          background: Store.requestFields.role === "Others" ? "#28DF99" : "#fff",
          color: Store.requestFields.role === "Others" ? "#fff" : "#393e46"
        }}
        onClick={() => Store.getRole("Others")}
        className="home-modal-btn m-b-10"
      >
        Others
      </Button>
    </div>

    <div className='color-grey-mid m-b-10'>Number of students</div>
    <div className='display-flex flex-wrap'>
      <Button
        style={{background:Store.requestFields.strength === '1 - 100' ? '#28DF99' : '#fff', color:Store.requestFields.strength === '1 - 100' ? '#fff' : '#393e46'}}
        onClick={() => Store.getStrength('1 - 100')}
        className='home-modal-btn m-r-10'>1 - 100</Button>
      <Button
        style={{background:Store.requestFields.strength === '101 - 1000' ? '#28DF99' : '#fff', color:Store.requestFields.strength === '101 - 1000' ? '#fff' : '#393e46'}}
        onClick={() => Store.getStrength('101 - 1000')}
        className='home-modal-btn m-r-10 m-b-10'>101 - 500</Button>
      {/* <Button
        style={{background:Store.requestFields.strength === '1000+' ? '#28DF99' : '#fff', color:Store.requestFields.strength === '1000+' ? '#fff' : '#393e46'}}
        onClick={() => Store.getStrength('1000+')}
        className='home-modal-btn m-b-10'>500+</Button> */}
    </div>

    {/*<Row className='m-t-20'>
      <Col xs={12} sm={6} className=''>
      </Col>
      <Col xs={12} sm={6} className="m-t-20 m-b-20">
        <Checkbox
          checked={Store.requestFields.requestDemo}
          onChange={e => (Store.requestFields.requestDemo = e.target.checked)}
        >
          Request Demo
        </Checkbox>
      </Col>
    </Row>*/}

    <Row className="">
      <Col xs={12} sm={6} className="m-t-20">
        <Input
          placeholder="Full Name*"
          className="home-modal-input"
          style={{
            minWidth: "250px",
            maxWidth: "550px",
            borderColor: Store.requestFields.name === "" ? "#F30E5C" : null
          }}
          onChange={e => (Store.requestFields.name = e.target.value)}
          value={Store.requestFields.name}
        />
        <div className="m-t-20"></div>
        <Input
          placeholder="Organisation name*"
          className="home-modal-input"
          style={{ minWidth: "250px", maxWidth: "550px" }}
          onChange={e => (Store.requestFields.orgName = e.target.value)}
          value={Store.requestFields.orgName}
        />
      </Col>
      <Col xs={12} sm={6} className="m-t-20">
        <Input
          placeholder="Email*"
          className="home-modal-input"
          style={{
            minWidth: "250px",
            maxWidth: "550px",
            borderColor: Store.requestFields.email === "" ? "#F30E5C" : null
          }}
          onChange={e => (Store.requestFields.email = e.target.value)}
          value={Store.requestFields.email}
        />
        <div className="m-t-20"></div>
        <PhoneInput
          defaultCountry="IN"
          className="support-input phone-input-wrapper"
          placeholder="Mobile number*"
          style={{
            borderColor: Store.requestFields.phone === "" ? "#F30E5C" : null,
            height: "32px",
            minWidth: "250px",
            maxWidth: "550px"
          }}
          value={Store.requestFields.phone}
          onChange={string => {
            if (string === undefined) {
              Store.requestFields.phone = "";
            } else {
              if (parsePhoneNumber(string) !== undefined) {
                Store.requestFields.phone = parsePhoneNumber(string).number;
                Store.requestFields.country = parsePhoneNumber(string).country;
              }
            }
          }}
        />
      </Col>
    </Row>
    <Row className="m-t-20">
      <Col xs={12} sm={12} className="">
        <TextArea
          placeholder="Let us know what you need"
          className="home-modal-input"
          style={{ minWidth: "250px", maxWidth: "780px", minHeight: "80px" }}
          onChange={e => (Store.requestFields.knowMore = e.target.value)}
          value={Store.requestFields.knowMore}
          autoSize={true}
        />
      </Col>
    </Row>

    <div className="r-c-c m-t-30">
      <Button
        loading={Store.requestFieldsLoader}
        onClick={() => Store.requestDemo('aulas')}
        size={"large"}
        className="font-600 home-modal-submit-btn"
      >
        <span className="font-600">SUBMIT</span>
      </Button>
    </div>
  </Modal>
);

export default observer(RequestModal);
