import React from 'react'
import { Helmet } from 'react-helmet'

const MetaShareOg = ({title, description, imageUrl, imageAlt}) => {
  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <meta property='og:title' content={title}/>
            <meta name='description' content={description}/>
            <meta property='og:description' content={description}/>
            <meta property='og:image' content={imageUrl}/>
            <meta property='og:url' content={window.location.pathname + window.location.search}/>
            <meta property='twitter:image:alt' content={imageAlt}/>
        </Helmet>
    </div>
  )
}

export default MetaShareOg